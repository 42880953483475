/* global window, document */
// @flow
import React, { useEffect } from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga';

import PrivacyBanner from '../components/privacy-banner/privacy-banner.component';
import Language from './language/language';
import { privacy, stories } from '../config/config';

import { trackPageView, track } from '../util/helpers';

const ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  : 'UA-100789646-17';

ReactGA.initialize(ANALYTICS_ID, { titleCase: false });
ReactGA.set({ displayFeaturesTask: null });
ReactGA.set({ anonymizeIp: true });
ReactGA.set({ transport: 'beacon' });

let passed15Seconds = false;

setTimeout(() => {
  passed15Seconds = true;
}, 15000);

window.addEventListener('beforeunload', () => {
  track(
    'PageExit',
    'Unload',
    passed15Seconds ? 'Exit after 15 seconds' : 'Exit before 15 seconds',
    !passed15Seconds,
  );
});

window.isTouch = false;
if ('ontouchstart' in window) {
  window.isTouch = true;
}

window.locale = 'en';

const storyMap = {};

for (let i = 0; i < stories.length; i++) {
  const story = stories[i];
  const { url } = story;
  const languages = Object.keys(url);

  for (let j = 0; j < languages.length; j++) {
    const language = languages[j];
    const localUrl = url[language];

    storyMap[localUrl] = story;
  }
}

window.stories = storyMap;

const getUrl = ({ pathname, search, hash }) => `${pathname}${search}${hash}`;

const App = () => {
  const location = useLocation();

  useEffect(() => {
    let timeout = setTimeout(() => {
      trackPageView(document.title, getUrl(location));
    }, 1000);

    return () => {
      if (timeout) {
        timeout = clearTimeout(timeout);
      }
    };
  }, [location]);

  return (
    <>
      <div
        className={`${
          window.Modernizr && !window.Modernizr.cssgrid ? ' no-grid' : ''
        }`}
      >
        <Switch>
          <Route path="/:language">
            <Language />
          </Route>
          <Redirect from="*" to="/en" />
        </Switch>
      </div>
      <PrivacyBanner data={privacy} />
    </>
  );
};
export default App;
