// @flow
import React from 'react';
import { track, localize } from '../../util/helpers';
import { isPrivacyBannerClosed, onPrivacyBannerClosed } from '../../util/cookies';

import Close from '../../assets/img/cross_black.svg';

import styles from './privacy-banner.module.scss';

interface Props {
  data: {
    text: string,
  },
}

interface State {
  hidden: boolean,
}

class PrivacyBanner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hidden: isPrivacyBannerClosed(),
    };
  }


  closeButton = () => {
    track('Privacy', 'Privacy Banner Closed', '');

    onPrivacyBannerClosed();

    this.setState({
      hidden: true,
    });
  };

  render() {
    const {
      hidden,
    } = this.state;

    const {
      data,
    } = this.props;

    return (
      <div className={`${styles.privacyBanner} ${hidden ? '' : styles.visible}`}>
        <div className={styles.innerContainer}>
          <p
            className={styles.privacyText}
            dangerouslySetInnerHTML={{ __html: localize(data.text) }}
          />
          <img src={Close} alt="close" className={styles.acceptBtn} onClick={this.closeButton} />
        </div>
      </div>
    );
  }
}

export default PrivacyBanner;
