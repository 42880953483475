// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import plus from '../../../../assets/img/plus.svg';

import styles from './image.module.scss';

interface Props {
  data: {
    type: string,
    src: string,
    details?: {
      title?: {[string]: string},
      copy: Array<{[string]: string}>,
    },
    credit?: {[string]: string},
  }
}

const Image = ({ data } : Props) => {
  let detailsIcon;
  let details;

  if (data.details) {
    detailsIcon = (
      <div className={`${styles.detailsIcon} ${data.credit ? styles.hasCredit : ''}`}>
        <img alt="details" src={plus} />
      </div>
    );

    let title;
    let pContainer;

    if (data.details.copy.length > 0) {
      const paragraphs = data.details.copy.map((paragraph, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{ __html: localize(paragraph) }}
        />
      ));

      pContainer = (
        <div className={styles.pContainer}>
          {paragraphs}
        </div>
      );
    }

    if (data.details.title) {
      title = (
        <h4
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: localize(data.details.title),
          }}
        />
      );
    }

    details = (
      <div className={styles.detailsContainer}>
        <div className={`${styles.detailsContent} ${data.credit ? styles.hasCredit : ''}`}>
          {title}
          {pContainer}
        </div>
      </div>
    );
  }

  let extraStyles = '';

  if (data.type) {
    const types = data.type.split(' ');

    for (let i = 0; i < types.length; i++) {
      const type = types[i];

      if (type && type.trim().length > 0) {
        extraStyles += `${styles[type]} `;
      }
    }
  }

  let credit;

  if (data.credit) {
    credit = (
      <div
        className={styles.credit}
      >
        <p
          className="tiny"
          dangerouslySetInnerHTML={{ __html: localize(data.credit) }}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.image} ${extraStyles} scroll-animation-component`}>
      <img src={data.src} alt="" />
      {credit}
      {detailsIcon}
      {details}
    </div>
  );
};

export default Image;
