// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './quote.module.scss';
import QuotationOpen from './quotation_open.svg';
import QuotationClose from './quotation_close.svg';

interface Props {
  data: {
    text: {[string]: string},
    author?: {[string]: string},
  }
}

const Quote = ({ data } : Props) => {
  let sourceComponent;

  if (data.author) {
    sourceComponent = (
      <h5 className={styles.source}>{localize(data.author)}</h5>
    );
  }

  return (
    <div className={`${styles.quote} scroll-animation-component`}>
      <img className={styles.quotemarkOpen} src={QuotationOpen} alt="Quote mark open" />
      <h3
        className={styles.quoteText}
        dangerouslySetInnerHTML={{
          __html: `${localize(data.text)}<div class="${styles.quotemarkContainer}"><img class="${styles.quotemarkClose}" src="${QuotationClose}" alt="Quote mark close" /></div>`,
        }}
      />
      {sourceComponent}
    </div>
  );
};

export default Quote;
