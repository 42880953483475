// @flow
import React from 'react';

import styles from './hero.module.scss';

import { localize } from '../../../../util/helpers';

interface Props {
  data: {
    title: {[string]: string}
  },
}

const Hero = ({ data }: Props) => (
  <div className={styles.heroComponent}>
    <div className={`${styles.content}`}>
      <div className={styles.title}>
        <h2>{localize(data.title).replace('<br/>', ' ')}</h2>
      </div>
    </div>
  </div>
);

export default Hero;
