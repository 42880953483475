// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './square-video.module.scss';

interface Props {
  data: {
    text?: {[string]: string},
    videoId: {[string]: string},
    videoThumbnail?: string,
    noScrollAnimation: boolean,
  },
}

const SquareVideo = ({ data } : Props) => {
  const videoSrc = `https://www.youtube-nocookie.com/embed/${localize(data.videoId)}?modestbranding=1&rel=0`;

  let text;

  if (data.text) {
    text = (
      <div className={styles.paragraphWrapper}>
        <p
          dangerouslySetInnerHTML={{
            __html: localize(data.text),
          }}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.squareVideo}${data.noScrollAnimation ? '' : ' scroll-animation-component'}`}>
      <div className={styles.videoWrapper}>
        <div className={styles.content}>
          {!data.videoThumbnail ? (
            <iframe
              title="video-player"
              className={styles.ytPlayer}
              src={videoSrc}
              frameBorder="0"
              type="text/html"
            />
          ) : (
            <img src={data.videoThumbnail} alt="Thumbnail for video" className={styles.thumbnail} />
          )}
        </div>
      </div>
      {text}
    </div>
  );
};

export default SquareVideo;
