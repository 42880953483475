/* global window, document */
// @flow
import React, {
  useCallback, useState, useEffect, useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from './menu/menu.component';
import MenuIcon from './menu-icon/menu-icon.component';
import styles from './header.module.scss';

import LogoImg from './logo_white.svg';
import LogoImgBlack from './logo_black.svg';

import { track } from '../../util/helpers';
import { useWindowDimensions } from '../../util/hooks';

import AppContext from '../../app/context';

const checkHome = (pathname) => pathname.split('/').length <= 2;

const Header = () => {
  const { transition } = useContext(AppContext);
  const dimensions = useWindowDimensions();
  const [isMobile, setMobile] = useState(dimensions.width < 1024);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const [isHome, setHome] = useState(checkHome(pathname));

  useEffect(() => {
    setHome(checkHome(pathname));
  }, [pathname]);

  useEffect(() => {
    if (dimensions.width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [dimensions.width]);
  const onMenuClick = useCallback(() => {
    const newVisible = !visible;

    if (document.body != null) {
      if (newVisible) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }

    if (newVisible) {
      track('Menu', 'Click', 'Open Menu');
    } else {
      track('Menu', 'Click', 'Close Menu');
    }

    setVisible(newVisible);
  }, [visible]);

  const goToRoute = useCallback((newRoute: string) => {
    if (pathname === newRoute) {
      setVisible(false);

      if (document.body != null) {
        document.body.classList.remove('no-scroll');
      }
      return;
    }

    track('Menu Item', 'Click', newRoute);

    transition.toggle();

    setTimeout(() => {
      window.scrollTo(0, 0);
      setVisible(false);
    }, 400);

    setTimeout(() => {
      history.push(newRoute);

      if (document.body != null) {
        document.body.classList.remove('no-scroll');
      }
    }, 600);
  }, [history, pathname, transition]);

  const onLogoClick = useCallback(() => {
    if (isHome) {
      return;
    }

    transition.toggle();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 400);

    setTimeout(() => {
      history.push(`/${window.locale}`);
    }, 600);
  }, [history, isHome, transition]);

  const logo = (isHome && isMobile) ? LogoImg : LogoImgBlack;

  return (
    <div
      className={`${styles.header} ${visible ? styles.visible : ''}`}
    >
      <img src={logo} alt="logo" onClick={onLogoClick} />
      <Menu
        visible={visible}
        goToRoute={goToRoute}
      />
      <MenuIcon
        visible={visible}
        onClick={onMenuClick}
        useBlack={!(isHome && isMobile)}
      />
    </div>
  );
};

export default Header;
