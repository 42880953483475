/* global window */
// @flow
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import NavigationMobile from '../../components/navigation-mobile/navigation';
import NavigationDesktop from '../../components/navigation-desktop/navigation';

import { useWindowDimensions } from '../../util/hooks';

import styles from './home.module.scss';

import { stories } from '../../config/config';
import copy from '../../config/copy';
import { localize } from '../../util/helpers';

const Home = () => {
  const dimensions = useWindowDimensions();
  const [isMobile, setMobile] = useState(dimensions.width < 1024);

  useEffect(() => {
    if (dimensions.width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [dimensions.width]);

  const storyItems = stories.map(({
    id, url, title, navImg, navMobile, position, orientation, contentPosition,
  }) => ({
    id, url, title, navImg, navMobile, position, orientation, contentPosition,
  }));

  return (
    <div className={`${styles.home} no-scroll`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Essity x Fotografiska</title>
        <link rel="canonical" href={`${window.location.origin}/${window.locale}`} />
        <meta name="og:title" content="Essity x Fotografiska" />
        <meta name="og:description" content={localize(copy.essityxfotografiska.text10)} />
      </Helmet>
      {isMobile
        ? <NavigationMobile items={storyItems} /> : <NavigationDesktop items={storyItems} />}
    </div>
  );
};

export default Home;
