/* global window */
// @flow
import React, {
  useState, useCallback, useEffect, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';

import styles from './next.module.scss';

import { localize } from '../../../../util/helpers';
import { useWindowDimensions } from '../../../../util/hooks';

import AppContext from '../../../../app/context';

interface Props {
  data: {
    src: string,
    mobileSrc: string,
    position: {[string]: string},
    copy: {[string]: string},
    nextTitle: {[string]: string},
    nextUrl: {[string]: string},
    nextId: number,
  },
}

const getBackgroundImage = (width, src, mobileSrc) => {
  if (width < 1024) {
    return `url(${mobileSrc})`;
  }
  return `url(${src})`;
};

const Next = ({ data }: Props) => {
  const ref = useRef();
  const history = useHistory();
  const dimensions = useWindowDimensions();
  const [nextState, setNextState] = useState({
    clicked: false, transform: dimensions.height * 0.4,
  });
  const [backgroundImage, setBackgroundImage] = useState(getBackgroundImage(
    dimensions.width, data.src, data.mobileSrc,
  ));
  const { transition } = useContext(AppContext);

  useEffect(() => {
    setNextState({ clicked: false, transform: dimensions.height * 0.4 });
  }, [data.src, dimensions.height]);

  useEffect(() => {
    setBackgroundImage(getBackgroundImage(
      dimensions.width, data.src, data.mobileSrc,
    ));
  }, [data.mobileSrc, data.src, dimensions.width]);

  const onNextClick = useCallback(() => {
    if (transition.show) {
      return;
    }
    transition.toggle();

    setTimeout(() => {
      history.push(`/${window.locale}/${localize(data.nextUrl)}`);
    }, 500);
  }, [data.nextUrl, history, transition]);

  const style : {backgroundImage: string, transform?: string } = { backgroundImage };

  if (nextState.clicked) {
    style.transform = `translateY(-${nextState.transform}px)`;
  }

  return (
    <div
      ref={ref}
      onClick={onNextClick}
      className={`${styles.nextComponent} ${nextState.clicked ? styles.nextClicked : ''}`}
      style={style}
    >
      <div className={styles.overlay} />
      <div className={styles.content}>
        <div className={styles.details}>
          <p className={`super-tiny regular ${styles.position}`}>{localize(data.position)}</p>
          <h6
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: localize(data.nextTitle) }}
          />
          <p className="super-tiny regular">{localize(data.copy)}</p>
        </div>
      </div>
    </div>
  );
};

export default Next;
