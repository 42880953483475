// @flow
import React from 'react';

import { track } from '../../util/helpers';
import images from '../../config/images';

import styles from './logos.module.scss';

interface Props {
  className?: string,
  style?: Object,
  isWhite?: boolean,
  category: string,
}

const Logos = ({
  className, style, isWhite, category,
} : Props) => (
  <div className={`${styles.logos} ${className || ''}`} style={style}>
    <a
      className={styles.essity}
      href="https://www.essity.com"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => track(category, 'Click', 'Essity Logo')}
    >
      <img
        src={isWhite ? images.essityLogoWhite : images.essityLogo}
        alt="Essity Logo"
      />
    </a>
    <a
      className={styles.fotografiska}
      href="https://www.fotografiska.com/sto"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => track(category, 'Click', 'Fotografiska Logo')}
    >
      <img
        src={isWhite ? images.fotografiskaLogoWhite : images.fotografiskaLogo}
        alt="Fotografiska Logo"
      />
    </a>
  </div>
);

export default Logos;
