/* global window */
// @flow
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './navigation-item.module.scss';
import { localize } from '../../../util/helpers';

import AppContext from '../../../app/context';

export interface Data {
  id: number,
  title: {[string]: string},
  url: {[string]: string},
  navImg: string,
  navMobile: string,
  position: string,
  contentPosition: string,
  orientation: string,
}

interface Props {
  data: Data,
  passive: boolean,
  active: boolean,
  onActiveChange: (id: number, isActive: boolean) => void,
}

const NavigationItem = ({
  data, active, passive, onActiveChange,
} : Props) => {
  const {
    id, title, url, navMobile, orientation, position, contentPosition,
  } = data;
  const history = useHistory();
  const { transition } = useContext(AppContext);

  const onClick = useCallback(() => {
    if (transition.show) {
      return;
    }
    transition.toggle();

    setTimeout(() => {
      history.push(`/${window.locale}/${localize(url)}`);
    }, 500);
  }, [history, transition, url]);

  const onMouseEnter = useCallback(() => {
    onActiveChange(id, true);
  }, [id, onActiveChange]);

  const onMouseLeave = useCallback(() => {
    onActiveChange(id, false);
  }, [id, onActiveChange]);

  return (
    <div
      className={`
        ${styles.navigationItem} 
        ${styles[orientation]} 
        ${passive ? styles.passive : ''} 
        ${active ? styles.active : ''}
      `}
      style={{ backgroundImage: `url(${navMobile})` }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={`${styles.content} ${styles[contentPosition]}`}>
        <p className="super-tiny medium">{position}</p>
        <div className={styles.title}>{localize(title)}</div>
      </div>
    </div>
  );
};

export default NavigationItem;
