/* global window */
// @flow
import ReactGA from 'react-ga';
import copy from '../config/copy';

export const track = (
  category: string, action: string, label: string, nonInteraction?: boolean,
) => {
  ReactGA.event({
    category,
    action,
    label,
    nonInteraction: nonInteraction === true,
  });
};

export const trackPageView = (title: string, location: string) => {
  ReactGA.pageview(location, undefined, title);
};

export const getDistance = (point1: Object, point2: Object) => {
  const x = point1.x - point2.x;
  const y = point1.y - point2.y;

  return Math.sqrt(x * x + y * y);
};

export const getOverlayMetadata = (state: Object) => {
  let ret = {};

  if (state.aboutOpen) {
    ret = {
      data: {
        title: copy.essityxfotografiska.text4,
        url: copy.essityxfotografiska.text8,
      },
    };
  } else if (state.reportOpen) {
    ret = {
      data: {
        title: copy.essityxfotografiska.text5,
        url: copy.essityxfotografiska.text7,
      },
    };
  }
  return ret;
};

export const localize = (copyItem?: { [key: string]: string } | string) : string => {
  let localized;

  if (copyItem != null) {
    if (typeof copyItem === 'string') {
      localized = copyItem;
    } else {
      localized = copyItem[window.locale];

      if (localized == null) {
        localized = copyItem.en;
      } else if (localized.trim() === '__BLANK__') {
        localized = '';
      }
    }
  }

  return localized || '';
};
