import images from '../images';

import copy from '../copy';

const content = [
  {
    component: 'hero',
    data: {
      src: images.hero.story4,
      mobileSrc: images.heroMobile.story4,
      title: copy.story4.text1,
      explore: copy.essityxfotografiska.text1,
      position: '4/5',
    },
  },
  {
    component: 'grid',
    data: {
      content: [
        {
          component: 'bigImage',
          data: {
            src: images.content.story4.img1,
            noAnimation: true,
          },
        },
        {
          component: 'intro',
          data: {
            copy: [copy.story4.text6],
          },
        },
      ],
    },
  },
  {
    component: 'text',
    data: {
      title: copy.story4.text40,
      copy: [copy.story4.text4],
    },
  },
  {
    component: 'text',
    data: {
      copy: [copy.story4.text8],
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story4.text9,
      copy: [copy.story4.text10],
      linkText: copy.story4.text11,
      src: copy.story4.text12,
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape left',
      src: images.content.story4.img2,
      details: {
        copy: [copy.story4.text13],
      },
      credit: copy.story4.text38,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story4.text14,
      copy: [copy.story4.text15],
      linkText: copy.story4.text16,
      src: copy.story4.text17,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story4.text18],
      linkText: copy.story4.text19,
      src: copy.story4.text20,
    },
  },
  {
    component: 'bigImage',
    data: {
      src: images.content.story4.img3,
      details: {
        copy: [copy.story4.text21],
      },
      credit: copy.story4.text39,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story4.text22,
      copy: [copy.story4.text23],
      linkText: copy.story4.text24,
      src: copy.story4.text25,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story4.text26,
      note: copy.story4.text27,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story4.text28],
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story4.text31,
      copy: [copy.story4.text32],
      linkText: copy.story4.text33,
      src: copy.story4.text34,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story4.text35,
      note: copy.story4.text36,
    },
  },
  {
    component: 'share',
    data: {
      share: copy.essityxfotografiska.text6,
    },
  },
  {
    component: 'next',
    data: {
      src: images.hero.story5,
      mobileSrc: images.heroMobile.story5,
      copy: copy.essityxfotografiska.text2,
      position: '5/5',
      nextTitle: copy.story5.text1,
      nextUrl: copy.story5.text2,
      nextId: 4,
    },
  },
];

/* eslint-enable */

export default content;
