// @flow
import React from 'react';

import styles from './menu-icon.module.scss';

interface Props {
  visible: boolean;
  onClick: Function;
  useBlack: boolean;
}

const MenuIcon = ({ visible, useBlack, onClick }: Props) => (
  <div
    className={`${styles.menuIcon}${visible ? ` ${styles.visible}` : ''} ${
      useBlack ? styles.black : ''
    }`}
    onClick={onClick}
  >
    <div className={styles.menuIconLine} />
    <div className={styles.menuIconLine} />
  </div>
);

export default MenuIcon;
