import images from '../images';

import copy from '../copy';

const content = [
  {
    component: 'hero',
    data: {
      src: images.hero.story5,
      mobileSrc: images.heroMobile.story5,
      title: copy.story5.text1,
      explore: copy.essityxfotografiska.text1,
      position: '5/5',
    },
  },
  {
    component: 'grid',
    data: {
      content: [
        {
          component: 'bigImage',
          data: {
            src: images.content.story5.img1,
            details: {
              copy: [copy.story5.text7],
            },
            noAnimation: true,
          },
        },
        {
          component: 'intro',
          data: {
            copy: [copy.story5.text6],
          },
        },
      ],
    },
  },
  {
    component: 'text',
    data: {
      title: copy.story5.text32,
      copy: [copy.story5.text4],
    },
  },
  {
    component: 'text',
    data: {
      copy: [copy.story5.text8],
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story5.text9,
      copy: [copy.story5.text10],
      linkText: copy.story5.text11,
      src: copy.story5.text12,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story5.text13,
      note: copy.story5.text14,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story5.text15],
      linkText: copy.story5.text16,
      src: copy.story5.text17,
    },
  },
  {
    component: 'image',
    data: {
      type: 'portrait right',
      src: images.content.story5.img2,
      details: {
        copy: [copy.story5.text18],
      },
      credit: copy.story5.text31,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story5.text19,
      copy: [copy.story5.text20],
      linkText: copy.story5.text21,
      src: copy.story5.text22,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story5.text23],
      linkText: copy.story5.text24,
      src: copy.story5.text25,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small right',
      videoId: copy.story5.text26,
      note: copy.story5.text27,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story5.text28,
      note: copy.story5.text29,
    },
  },
  {
    component: 'share',
    data: {
      share: copy.essityxfotografiska.text6,
    },
  },
  {
    component: 'next',
    data: {
      src: images.hero.story1,
      mobileSrc: images.heroMobile.story1,
      copy: copy.essityxfotografiska.text2,
      position: '1/5',
      nextTitle: copy.story1.text1,
      nextUrl: copy.story1.text2,
      nextId: 0,
    },
  },
];

/* eslint-enable */

export default content;
