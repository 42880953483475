import images from '../images';

import copy from '../copy';

const content = [
  {
    component: 'hero',
    data: {
      src: images.hero.story1,
      mobileSrc: images.heroMobile.story1,
      title: copy.story1.text1,
      explore: copy.essityxfotografiska.text1,
      position: '1/5',
    },
  },
  {
    component: 'intro',
    data: {
      copy: [
        copy.story1.text5,
        copy.story1.text6,
      ],
      noAnimation: true,
    },
  },
  {
    component: 'bigImage',
    data: {
      src: images.content.story1.img1,
      details: {
        copy: [copy.story1.text7],
      },
      credit: copy.story1.text25,
      noAnimation: true,
    },
  },
  {
    component: 'quote',
    data: {
      text: copy.story1.text8,
      author: copy.story1.text9,
    },
  },
  {
    component: 'image',
    data: {
      type: 'portrait right',
      src: images.content.story1.img2,
      details: {
        copy: [copy.story1.text10],
      },
      credit: copy.story1.text26,
    },
  },
  {
    component: 'quote',
    data: {
      text: copy.story1.text11,
      author: copy.story1.text12,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story1.text19,
      note: copy.story1.text13,
    },
  },
  {
    component: 'quote',
    data: {
      text: copy.story1.text14,
      author: copy.story1.text15,
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape right',
      src: images.content.story1.img3,
      details: {
        copy: [copy.story1.text16],
      },
      credit: copy.story1.text27,
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape left',
      src: images.content.story1.img4,
      details: {
        copy: [copy.story1.text17],
      },
      credit: copy.story1.text28,
    },
  },
  {
    component: 'quote',
    data: {
      text: copy.story1.text22,
      author: copy.story1.text23,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small right',
      videoId: copy.story1.text20,
      note: copy.story1.text24,
    },
  },
  {
    component: 'share',
    data: {
      share: copy.essityxfotografiska.text6,
    },
  },
  {
    component: 'next',
    data: {
      src: images.hero.story2,
      mobileSrc: images.heroMobile.story2,
      copy: copy.essityxfotografiska.text2,
      nextTitle: copy.story2.text1,
      nextUrl: copy.story2.text2,
      position: '2/5',
      nextId: 1,
    },
  },
];

export default content;
