/* global window */
// @flow
import React, { useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';

import 'swiper/dist/css/swiper.min.css';

import { localize, track } from '../../../../util/helpers';
import { useWindowDimensions } from '../../../../util/hooks';

import leftArrow from '../../../../assets/img/left_arrow.svg';
import rightArrow from '../../../../assets/img/right_arrow.svg';
import leftArrowWhite from '../../../../assets/img/left_arrow_white.svg';
import rightArrowWhite from '../../../../assets/img/right_arrow_white.svg';

import styles from './image-slider.module.scss';

interface Props {
  data: {
    id: string,
    type: string,
    title: {[string]: string},
    src?: {[string]: string},
    linkText?: {[string]: string},
    image: {[string]: string},
    credit?: {[string]: string},
    items: Array<{
      key: string,
      title: {[string]: string},
      text?: {[string]: string},
    }>,
  }
}

const ImageSlider = ({ data } : Props) => {
  const swiperRef = useRef();

  useEffect(() => {
    if (swiperRef.current) {
      const { swiper } = swiperRef.current;

      if (swiper) {
        swiper.on('slideChange', () => {
          track('Slider', 'Slide Change', `${data.id} Page ${swiper.realIndex}`);
        });
      }
    }
  }, [data.id]);

  const dimensions = useWindowDimensions();

  let isMobile = false;

  if (dimensions.width < 500) {
    isMobile = true;
  }

  let source;

  if (data.linkText) {
    let content;

    if (data.src) {
      content = (
        <a
          className="medium"
          href={localize(data.src)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {localize(data.linkText)}
        </a>
      );
    } else {
      content = localize(data.linkText);
    }

    source = (
      <h5 className={styles.source}>
        {content}
      </h5>
    );
  }

  const swiperOptions = {
    loop: true,
    navigation: {
      nextEl: `.${styles.arrow}.${styles.right}.${data.id}`,
      prevEl: `.${styles.arrow}.${styles.left}.${data.id}`,
    },
  };

  const items = data.items.map((item) => {
    let itemText;

    const itemTitle = (
      <h1 className={styles.itemTitle}>{localize(item.title)}</h1>
    );

    if (item.text) {
      itemText = (
        <p
          className={styles.itemText}
          dangerouslySetInnerHTML={{ __html: localize(item.text) }}
        />
      );
    }

    return (
      <div className={styles.slideItem} key={item.key}>
        <div className={styles.contentContainer}>
          {itemTitle}
          {itemText}
        </div>
      </div>
    );
  });

  const isWhite = isMobile || (window.Modernizr && !window.Modernizr.cssgrid);

  let credit;

  if (data.credit) {
    credit = (
      <div
        className={styles.credit}
      >
        <p
          className="tiny"
          dangerouslySetInnerHTML={{ __html: localize(data.credit) }}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.imageSlider} scroll-animation-component`}>
      <img className={styles.image} src={data.image} alt="" />
      {credit}
      <img
        className={`${styles.arrow} ${styles.left} ${styles.fake}`}
        src={isWhite ? leftArrowWhite : leftArrow}
        alt="Left Arrow"
      />
      <img
        className={`${styles.arrow} ${styles.right} ${styles.fake}`}
        src={isWhite ? rightArrowWhite : rightArrow}
        alt="Right Arrow"
      />
      <div className={styles.darkOverlay} />
      <div className={styles.slideContainer}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Swiper {...swiperOptions} ref={swiperRef}>
          {items}
        </Swiper>
      </div>
      <div className={styles.headerContainer}>
        <h4
          dangerouslySetInnerHTML={{
            __html: localize(data.title),
          }}
        />
        {source}
      </div>
    </div>
  );
};

export default ImageSlider;
