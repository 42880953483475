import FotografiskaLogo from '../assets/img/logos/fotografiska_logo.svg';
import FotografiskaLogoWhite from '../assets/img/logos/fotografiska_logo_white.svg';
import EssityLogo from '../assets/img/logos/essity_logo.svg';
import EssityLogoWhite from '../assets/img/logos/essity_logo_white.svg';

import Story1Hero from '../assets/img/hero_desktop/fotografiska.jpg';
import Story1HeroMobile from '../assets/img/hero_mobile/fotografiska.jpg';
import Story1NavMobile from '../assets/img/nav_mobile/hands_at_fotografiska.jpg';

import Story2Hero from '../assets/img/hero_desktop/everyday.jpg';
import Story2HeroMobile from '../assets/img/hero_mobile/everyday.jpg';
import Story2NavMobile from '../assets/img/nav_mobile/hands_in_everyday.jpg';

import Story3Hero from '../assets/img/hero_desktop/school.jpg';
import Story3HeroMobile from '../assets/img/hero_mobile/school.jpg';
import Story3NavMobile from '../assets/img/nav_mobile/hands_in_school.jpg';

import Story4Hero from '../assets/img/hero_desktop/work.jpg';
import Story4HeroMobile from '../assets/img/hero_mobile/work.jpg';
import Story4NavMobile from '../assets/img/nav_mobile/hands_at_work.jpg';

import Story5Hero from '../assets/img/hero_desktop/healthcare.jpg';
import Story5HeroMobile from '../assets/img/hero_mobile/healthcare.jpg';
import Story5NavMobile from '../assets/img/nav_mobile/hands_in_healthcare.jpg';

import Story1Content1 from '../assets/img/stories/fotografiska/fotografiska_story_image1@3x.jpg';
import Story1Content2 from '../assets/img/stories/fotografiska/fotografiska_story_image2@3x.jpg';
import Story1Content3 from '../assets/img/stories/fotografiska/fotografiska_story_image3@3x.jpg';
import Story1Content4 from '../assets/img/stories/fotografiska/fotografiska_story_image4@3x.jpg';

import Story2Content1 from '../assets/img/stories/everyday/everyday_story_image1@3x.jpg';
import Story2Content2 from '../assets/img/stories/everyday/everyday_story_stat_image@2x.jpg';
import Story2Content3 from '../assets/img/stories/everyday/everyday_story_image3@3x.jpg';

import Story3Content1 from '../assets/img/stories/school/school_story1@3x.jpg';
import Story3Content2 from '../assets/img/stories/school/school_story2@3x.jpg';
import Story3Content3 from '../assets/img/stories/school/school_story3@3x.jpg';
import Story3Content4 from '../assets/img/stories/school/school_story4@3x.jpg';

import Story4Content1 from '../assets/img/stories/work/work_story_image1@3x.jpg';
import Story4Content2 from '../assets/img/stories/work/work_story_image2.jpg';
import Story4Content3 from '../assets/img/stories/work/work_story_image3@2x.jpg';

import Story5Content1 from '../assets/img/stories/healthcare/healthcare_story_image1@3x.jpg';
import Story5Content2 from '../assets/img/stories/healthcare/healthcare_story_image2@3x.jpg';

import About from '../assets/img/about/hero.jpg';
import AboutMobile from '../assets/img/about/hero_mobile.jpg';

import AboutContent1 from '../assets/img/about/1.jpg';
import AboutContent2 from '../assets/img/about/2.jpg';
import AboutContent3 from '../assets/img/about/3.jpg';
import AboutContent4 from '../assets/img/about/4.jpg';

import Report from '../assets/img/report/hero.jpg';
import ReportMobile from '../assets/img/report/hero_mobile.jpg';

const images = {
  fotografiskaLogo: FotografiskaLogo,
  fotografiskaLogoWhite: FotografiskaLogoWhite,
  essityLogo: EssityLogo,
  essityLogoWhite: EssityLogoWhite,
  about: {
    desktop: About,
    mobile: AboutMobile,
    img1: AboutContent1,
    img2: AboutContent2,
    img3: AboutContent3,
    img4: AboutContent4,
  },
  report: {
    desktop: Report,
    mobile: ReportMobile,
  },
  videoThumbnails: {
    story1: {

    },
    story2: {

    },
    story4: {

    },
    story5: {

    },
  },
  hero: {
    story1: Story1Hero,
    story2: Story2Hero,
    story3: Story3Hero,
    story4: Story4Hero,
    story5: Story5Hero,
  },
  heroMobile: {
    story1: Story1HeroMobile,
    story2: Story2HeroMobile,
    story3: Story3HeroMobile,
    story4: Story4HeroMobile,
    story5: Story5HeroMobile,
  },
  nav: {
    story1: Story1Hero,
    story2: Story2Hero,
    story3: Story3Hero,
    story4: Story4Hero,
    story5: Story5Hero,
  },
  navMobile: {
    story1: Story1NavMobile,
    story2: Story2NavMobile,
    story3: Story3NavMobile,
    story4: Story4NavMobile,
    story5: Story5NavMobile,
  },
  content: {
    story1: {
      img1: Story1Content1,
      img2: Story1Content2,
      img3: Story1Content3,
      img4: Story1Content4,
    },
    story2: {
      img1: Story2Content1,
      img2: Story2Content2,
      img3: Story2Content3,
    },
    story3: {
      img1: Story3Content1,
      img2: Story3Content2,
      img3: Story3Content3,
      img4: Story3Content4,
    },
    story4: {
      img1: Story4Content1,
      img2: Story4Content2,
      img3: Story4Content3,
    },
    story5: {
      img1: Story5Content1,
      img2: Story5Content2,
    },
  },
};

export default images;
