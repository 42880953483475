/* global window */
// @flow
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import includes from 'array-includes';

import { getDefaultLanguage, availableLanguages } from '../../util/language';
import { localize } from '../../util/helpers';

import Story from '../story/story';
import Page from '../page/page';
import Home from '../home/home';
import Header from '../../components/header/header.component';
import AppContext from '../context';

import copy from '../../config/copy';
import { about, report } from '../../config/config';

import styles from './language.module.scss';

const Language = () => {
  const transitionTimeout = useRef();
  const [showTransition, setShowTransition] = useState(false);
  const [canRender, setCanRender] = useState(false);
  const { language } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (language !== null && includes(availableLanguages, language)) {
      window.locale = language;
      setCanRender(true);
      return;
    }

    history.push(`/${getDefaultLanguage()}`);
  }, [history, language]);

  const toggleTransition = useCallback(() => {
    if (transitionTimeout.current) {
      return;
    }

    setShowTransition(true);

    transitionTimeout.current = setTimeout(() => {
      setShowTransition(false);
      transitionTimeout.current = null;
    }, 1200);
  }, []);

  let content = null;

  if (canRender) {
    content = (
      <div lang={language}>
        <Header />
        <div className={`${styles.transition} ${showTransition ? styles.visible : ''}`} />
        <Switch>
          <Route exact path="/:language">
            <Home />
          </Route>
          <Route exact path={`/:language/${localize(copy.essityxfotografiska.text8)}`}>
            <Page
              type={about.type}
              content={about.content}
              title={about.title}
              description={about.description}
            />
          </Route>
          <Route exact path={`/:language/${localize(copy.essityxfotografiska.text7)}`}>
            <Page
              type={report.type}
              content={report.content}
              title={report.title}
              description={report.description}
            />
          </Route>
          <Route exact path="/:language/:title">
            <Story />
          </Route>
          <Redirect from="*" to="/en" />
        </Switch>
      </div>
    );
  }

  return (
    <AppContext.Provider
      value={
        {
          transition: { show: showTransition, toggle: toggleTransition },
        }
      }
    >
      {content}
    </AppContext.Provider>
  );
};

export default Language;
