/* global window, document, navigator */
// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import FacebookLogo from './facebook_small.svg';
import LinkedInLogo from './linkedin_small.svg';
import TwitterLogo from './twitter_small.svg';
import MailLogo from './mail_small.svg';

import styles from './share.module.scss';

interface Props {
  data: {
    report?: {[string]: string},
    reportUrl?: {[string]: string},
    share: {[string]: string},
  }
}

const Share = ({ data } : Props) => {
  const onShareClick = (e: Object, type: string) => {
    e.preventDefault();
    e.stopPropagation();

    const titleEl = document.querySelector('meta[name="og:title"][data-react-helmet="true"]');
    const shareTitle = titleEl ? titleEl.getAttribute('content') : '';
    const descrEl = document.querySelector('meta[name="og:description"][data-react-helmet="true"]');
    const shareDescr = descrEl ? descrEl.getAttribute('content') : '';
    const shareUrl = window.location.href;

    const email = 'blank@blank.com';

    const types = {};
    types.fb = {
      href: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    };

    types.twitter = {
      // $FlowFixMe
      href: `https://twitter.com/share?url=${shareUrl}&amp;text=${shareTitle}`,
    };

    types.linkedin = {
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`,
    };

    types.email = {
      // $FlowFixMe
      href: `mailto:${email}?Subject=${shareTitle}&body=${shareDescr}%0D%0A%0D%0A${shareUrl}`,
    };

    const shareType = types[type];

    const intWidth = type === 'email' ? '1' : '500';
    const intHeight = type === 'email' ? '1' : '400';
    const strResize = type === 'email' ? 'no' : 'yes';

    const strTitle = 'Social Share';
    const strParam = `width=${intWidth},height=${intHeight},resizable=${strResize},visible=none`;

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS) {
      const a = document.createElement('a');
      a.setAttribute('href', shareType.href);

      const dispatch = document.createEvent('HTMLEvents');
      dispatch.initEvent('click', true, true);
      a.dispatchEvent(dispatch);
    } else if (type === 'email') {
      window.open(shareType.href, '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none', '');
    } else {
      window.open(shareType.href, strTitle, strParam).focus();
    }
  };

  let report;

  if (data.report) {
    report = (
      <h4 className={styles.report}>
        <a
          href={localize(data.reportUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {localize(data.report)}
        </a>
      </h4>
    );
  }

  return (
    <div className={`${styles.share} scroll-animation-component`}>
      {report}
      <div className={styles.itemWrapper}>
        <p className={styles.paragraph}>
          {localize(data.share)}
          :
        </p>
        <div className={styles.shareItemWrapper}>
          <div className={styles.item} onClick={(e) => onShareClick(e, 'fb')}>
            <img className={styles.shareIcon} src={FacebookLogo} alt="" />
          </div>
          <div className={styles.item} onClick={(e) => onShareClick(e, 'linkedin')}>
            <img className={styles.shareIcon} src={LinkedInLogo} alt="" />
          </div>
          <div className={styles.item} onClick={(e) => onShareClick(e, 'twitter')}>
            <img className={styles.shareIcon} src={TwitterLogo} alt="" />
          </div>
          <div className={styles.item} onClick={(e) => onShareClick(e, 'email')}>
            <img className={styles.shareIcon} src={MailLogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
