/* global window */
// @flow
import {
  useEffect, useCallback, useState, useRef,
} from 'react';

export const useDebouncedCallback = (
  callback: (...args: any[]) => void,
  wait: number,
) => {
  // track args & timeout handle between calls
  const argsRef = useRef();
  const timeout = useRef();

  const cleanup = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, [cleanup]);

  return useCallback((...args: any[]) => {
    // capture latest args
    argsRef.current = args;

    // clear debounce timer
    cleanup();

    // start waiting again
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current);
      }
    }, wait);
  }, [callback, cleanup, wait]);
};

export const useWindowDimensions = (wait : number = 1000) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const callback = useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  const onResize = useDebouncedCallback(callback, wait);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return dimensions;
};

export const useWindowScroll = (callback : () => void) => {
  useEffect(() => {
    window.addEventListener('scroll', callback, { capture: true, passive: true });

    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [callback]);
};
