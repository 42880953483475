// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './text.module.scss';

interface Props {
  data: {
    noAnimation?: boolean,
    title?: {[string]: string},
    src?: {[string]: string},
    linkText?: {[string]: string},
    copy?: Array<{[string]: string}>,
  },
}

const Text = ({ data } : Props) => {
  let title;
  let link;
  let pContainer;

  if (data.copy && data.copy.length > 0) {
    const paragraphs = data.copy.map((paragraph, index) => (
      <p
        key={index}
        dangerouslySetInnerHTML={{ __html: localize(paragraph) }}
      />
    ));

    pContainer = (
      <div className={styles.pContainer}>
        {paragraphs}
      </div>
    );
  }

  if (data.title) {
    title = (
      <h4
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: localize(data.title),
        }}
      />
    );
  }

  if (data.linkText) {
    let content;

    if (data.src) {
      content = (
        <a
          className="medium"
          href={localize(data.src)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {localize(data.linkText)}
        </a>
      );
    } else {
      content = localize(data.linkText);
    }

    link = (
      <h5 className={styles.source}>
        {content}
      </h5>
    );
  }

  return (
    <div className={`${styles.text} ${data.noAnimation ? '' : 'scroll-animation-component'}`}>
      {title}
      {pContainer}
      {link}
    </div>
  );
};

export default Text;
