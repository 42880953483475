// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './intro.module.scss';

interface Props {
  data: {
    copy: Array<{[string]: string}>,
    noAnimation?: boolean,
  },
}

const Intro = ({ data } : Props) => {
  let pContainer;

  if (data.copy.length > 0) {
    const paragraphs = data.copy.map((paragraph, index) => (
      <p
        className="tiny"
        key={index}
        dangerouslySetInnerHTML={{ __html: localize(paragraph) }}
      />
    ));

    pContainer = (
      <div className={styles.pContainer}>
        {paragraphs}
      </div>
    );
  }

  return (
    <div
      className={`${styles.intro} ${data.noAnimation ? '' : 'scroll-animation-component'}`}
    >
      {pContainer}
    </div>
  );
};

export default Intro;
