/* global window */
// @flow
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './navigation-item.module.scss';
import { localize } from '../../../util/helpers';

import AppContext from '../../../app/context';

import navigationArrow from '../../../assets/img/navigation_arrow.svg';

export interface Data {
  id: number,
  title: {[string]: string},
  url: {[string]: string},
  navImg: string,
  navMobile: string,
}

interface Props {
  data: Data,
}

const NavigationItem = ({ data } : Props) => {
  const {
    title, url, navMobile,
  } = data;
  const history = useHistory();
  const { transition } = useContext(AppContext);

  const onClick = useCallback(() => {
    if (transition.show) {
      return;
    }
    transition.toggle();

    setTimeout(() => {
      history.push(`/${window.locale}/${localize(url)}`);
    }, 500);
  }, [history, transition, url]);

  return (
    <div
      className={`${styles.navigationItem}`}
      style={{ backgroundImage: `url(${navMobile})` }}
      onClick={onClick}
    >
      <div className={styles.overlay} />
      <div className={styles.title}>{localize(title)}</div>
      <img className={styles.arrow} src={navigationArrow} alt="Navigation Arrow" />
    </div>
  );
};

export default NavigationItem;
