import images from '../images';

import copy from '../copy';

const content = [
  {
    component: 'hero',
    data: {
      src: images.hero.story3,
      mobileSrc: images.heroMobile.story3,
      title: copy.story3.text1,
      explore: copy.essityxfotografiska.text1,
      position: '3/5',
    },
  },
  {
    component: 'grid',
    data: {
      content: [
        {
          component: 'bigImage',
          data: {
            src: images.content.story3.img1,
            details: {
              copy: [copy.story3.text7],
            },
            noAnimation: true,
          },
        },
        {
          component: 'intro',
          data: {
            copy: [copy.story3.text6],
          },
        },
      ],
    },
  },
  {
    component: 'text',
    data: {
      title: copy.story3.text40,
      copy: [copy.story3.text4],
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story3.text8,
      copy: [copy.story3.text9],
      linkText: copy.story3.text10,
      src: copy.story3.text11,
    },
  },
  {
    component: 'text',
    data: {
      copy: [copy.story3.text12],
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape right',
      src: images.content.story3.img2,
      details: {
        copy: [copy.story3.text13],
      },
      credit: copy.story3.text37,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story3.text14,
      copy: [copy.story3.text15],
      linkText: copy.story3.text16,
      src: copy.story3.text17,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story3.text18],
      linkText: copy.story3.text19,
      src: copy.story3.text20,
    },
  },
  {
    component: 'image',
    data: {
      type: 'portrait left',
      src: images.content.story3.img3,
      details: {
        copy: [copy.story3.text21],
      },
      credit: copy.story3.text38,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story3.text22,
      copy: [copy.story3.text23],
      linkText: copy.story3.text24,
      src: copy.story3.text25,
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape right',
      src: images.content.story3.img4,
      details: {
        copy: [copy.story3.text26],
      },
      credit: copy.story3.text39,
    },
  },
  {
    component: 'fact',
    data: {
      copy: [copy.story3.text27],
      linkText: copy.story3.text28,
      src: copy.story3.text29,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      videoId: copy.story3.text30,
      note: copy.story3.text31,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story3.text32,
      copy: [copy.story3.text33],
      linkText: copy.story3.text34,
      src: copy.story3.text35,
    },
  },
  {
    component: 'share',
    data: {
      share: copy.essityxfotografiska.text6,
    },
  },
  {
    component: 'next',
    data: {
      src: images.hero.story4,
      mobileSrc: images.heroMobile.story4,
      copy: copy.essityxfotografiska.text2,
      position: '4/5',
      nextTitle: copy.story4.text1,
      nextUrl: copy.story4.text2,
      nextId: 3,
    },
  },
];

export default content;
