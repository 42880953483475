/* global HTMLDivElement */
// @flow
import React from 'react';
import Swiper from 'react-id-swiper';

import 'swiper/dist/css/swiper.min.css';

import { localize, track } from '../../../../util/helpers';

import leftArrow from '../../../../assets/img/left_arrow.svg';
import rightArrow from '../../../../assets/img/right_arrow.svg';
import legendIcon from './legend.png';
import stat1 from './stat_1.png';
import stat2 from './stat_2.png';
import stat3 from './stat_3.png';
import stat4 from './stat_4.png';
import stat5 from './stat_5.png';

import styles from './stats-slider.module.scss';

interface Country {
  name: {[string]: string},
  left: {[string]: string},
  right: {[string]: string},
}

interface StatItem {
  key: string,
  countries: Array<Country>,
}

interface Props {
  data: {
    id: string,
    title?: {[string]: string},
    text?: {[string]: string},
    legend?: {
      left: {[string]: string},
      right: {[string]: string},
    },
    items: Array<StatItem>,
  }
}

class StatsSlider extends React.Component<Props> {
  swiperNode: { current: null | HTMLDivElement & { swiper: Object } } = React.createRef();

  componentDidMount() {
    if (this.swiperNode.current) {
      const { swiper } = this.swiperNode.current;
      if (swiper) {
        const { data } = this.props;

        swiper.on('slideChange', () => {
          track('Slider', 'Slide Change', `${data.id} Page ${swiper.realIndex}`);
        });
      }
    }
  }

  render() {
    const { data } = this.props;

    let title;
    let text;

    if (data.title) {
      title = (
        <h4
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: localize(data.title),
          }}
        />
      );
    }

    if (data.text) {
      text = (
        <p
          className={styles.copy}
          dangerouslySetInnerHTML={{
            __html: localize(data.text),
          }}
        />
      );
    }

    let headerContainer;

    if (text || title) {
      headerContainer = (
        <div className={styles.headerContainer}>
          {title}
          {text}
        </div>
      );
    }

    let legend;
    let legendMobile;

    if (data.legend) {
      const { left, right } = data.legend;

      legend = (
        <div className={styles.legend}>
          <div className={styles.legendLeft}>
            <span />
            <p>{localize(left)}</p>
          </div>
          <div className={styles.legendRight}>
            <div className={styles.legendHolder}>
              <img src={legendIcon} alt="Legend right" />
            </div>
            <p>{localize(right)}</p>
          </div>
        </div>
      );

      legendMobile = (
        <div className={`${styles.legend} ${styles.mobile}`}>
          <div className={styles.legendLeft}>
            <span />
            <p>{localize(left)}</p>
          </div>
          <div className={styles.legendRight}>
            <div className={styles.legendHolder}>
              <img src={legendIcon} alt="Legend right" />
            </div>
            <p>{localize(right)}</p>
          </div>
        </div>
      );
    }

    const items = data.items.map((item) => {
      const countries = item.countries.map((country, index) => {
        const leftPercentage = `${localize(country.left)}%`;
        const rightPercentage = `${localize(country.right)}%`;

        let stat;

        switch (index) {
          case 1:
            stat = stat2;
            break;
          case 2:
            stat = stat3;
            break;
          case 3:
            stat = stat4;
            break;
          case 4:
            stat = stat5;
            break;
          default:
            stat = stat1;
            break;
        }

        return (
          <div className={styles.country} key={index}>
            <div
              className={styles.countryLeftContainer}
              style={{ width: leftPercentage }}
            >
              <p className={`${styles.countryName} tiny`}>{localize(country.name)}</p>
              <p className={`${styles.leftPercentage} tiny`}>
                <b>{leftPercentage}</b>
              </p>
              <span className={styles.leftBlock} />
            </div>
            <div
              className={styles.countryRightContainer}
              style={{ width: rightPercentage }}
            >
              <p className={`${styles.countryName} tiny`}>{localize(country.name)}</p>
              <p className={`${styles.rightPercentage} tiny`}>
                <b>{rightPercentage}</b>
              </p>
              <div className={styles.rightBlock}>
                <span className={styles.scribbleBox} />
                <img className={styles.scribble} src={stat} alt="Scribble" />
              </div>
            </div>
          </div>
        );
      });

      return (
        <div className={styles.slideItem} key={item.key}>
          <div className={styles.contentContainer}>
            {countries}
          </div>
        </div>
      );
    });

    const swiperOptions = {
      loop: true,
      navigation: {
        nextEl: `.${styles.arrow}.${styles.right}.${data.id}`,
        prevEl: `.${styles.arrow}.${styles.left}.${data.id}`,
      },
    };

    return (
      <div className={`${styles.statsSlider} scroll-animation-component`}>
        {headerContainer}
        {legendMobile}
        <div className={styles.slideContainer}>
          <img className={`${styles.arrow} ${styles.left} ${styles.fake}`} src={leftArrow} alt="Left Arrow" />
          <img className={`${styles.arrow} ${styles.right} ${styles.fake}`} src={rightArrow} alt="Right Arrow" />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Swiper {...swiperOptions} ref={this.swiperNode}>
            {items}
          </Swiper>
        </div>
        {legend}
      </div>
    );
  }
}

export default StatsSlider;
