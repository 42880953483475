/* global window */
// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import StoryContent from '../../components/story-content/story-content';

import { localize, track } from '../../util/helpers';

const getMetaData = ({ metaDescr, url, title }) => ({
  title: localize(title).replace('<br/>', ' '),
  description: localize(metaDescr),
  canonical: `${window.location.origin}/${window.locale}/${localize(url)}`,
});

const Story = () => {
  const { language, title } = useParams();
  const history = useHistory();

  const [story, setStory] = useState(null);

  useEffect(() => {
    let timeout;

    if (story) {
      timeout = setTimeout(() => {
        track('Story', '15 Seconds', `Story ${story.id + 1}`);
        timeout = null;
      }, 15000);
    }

    return () => {
      if (timeout) {
        timeout = clearTimeout(timeout);
      }
    };
  }, [story]);

  useEffect(() => {
    const storyFound = window.stories[title];

    if (!storyFound) {
      history.push(`/${language}`);
    } else {
      setStory(storyFound);
    }
  }, [title, language, history]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [title]);

  let content;

  if (story !== null) {
    const metaData = getMetaData(story);

    content = (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaData.title}</title>
          <link rel="canonical" href={`${metaData.canonical}`} />
          <meta name="og:title" content={metaData.title} />
          <meta name="og:description" content={metaData.description} />
        </Helmet>
        <StoryContent
          storyId={story.id}
          content={story.content}
          isBlack
        />
      </>
    );
  } else {
    content = <div />;
  }

  return (
    <>
      {content}
    </>
  );
};

export default Story;
