// @flow
import React from 'react';

import Logos from '../logos/logos';
import NavigationItem from './navigation-item/navigation-item';
import type { Data as NavigationItemData } from './navigation-item/navigation-item';

import styles from './navigation.module.scss';

interface Props {
  items: Array<NavigationItemData>,
}

const Navigation = ({ items } : Props) => {
  const itemsRender = items.map((item) => (
    <NavigationItem
      key={item.id}
      data={item}
    />
  ));

  return (
    <div className={styles.navigation}>
      {itemsRender}
      <Logos category="Navigation" style={{ paddingLeft: '30px', marginTop: '40px' }} />
    </div>
  );
};

export default Navigation;
