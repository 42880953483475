/* global window */
// @flow
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import StoryContent from '../../components/story-content/story-content';
import Logos from '../../components/logos/logos';

import { localize } from '../../util/helpers';

import styles from './page.module.scss';

interface Props {
  content: Array<Object>,
  type: string,
  title: string,
  description: string,
}

const Page = ({
  content, type, title, description,
} : Props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  return (
    <div className={styles.page}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{localize(title)}</title>
        <link rel="canonical" href={`${window.location.origin}/${window.locale}/${type}`} />
        <meta name="og:title" content={localize(title)} />
        <meta name="og:description" content={localize(description)} />
      </Helmet>
      <StoryContent
        storyId={type}
        content={content}
        isBlack
      />
      <Logos category={type} />
    </div>
  );
};

export default Page;
