/* eslint-disable */

export default {
  "story5": {
    "text26": {
      "en": "zumg27x7t70",
      "ru": "zumg27x7t70",
      "es": "zumg27x7t70"
    },
    "text25": {
      "en": "https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/",
      "ru": "https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/",
      "es": "https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/"
    },
    "text28": {
      "en": "ZlS3OIBVv08",
      "ru": "eIm_nX6XPK0",
      "es": "HPFz4HIreqg"
    },
    "text27": {
      "en": "Our Incontinence Solutions Team have produced the world’s first usability study into incontinence products. This is used in nursing home environments to improve efficiency of incontinence care which has the potential to save 13 weeks of caregivers’ time per year.",
      "ru": "Наша группа разработчиков решений по уходу при недержании выпустила первое в мире исследование потребительских свойств изделий по уходу при недержании. Его результаты используют дома престарелых для повышения эффективности ухода при недержании, что потенциально помогает экономить 13 недель времени специалистов по уходу в год.",
      "es": "Nuestro equipo de Soluciones para la Incontinencia ha llevado a cabo el primer estudio mundial de usabilidad de productos para la incontinencia. Esto se utiliza en entornos de hogares de ancianos a fin de mejorar la eficacia del cuidado de la incontinencia, que tiene el potencial de ahorrarles 13 semanas de tiempo por año a los cuidadores."
    },
    "text29": {
      "en": "Our Global Brand Director in IQ Solutions talks about the work we do in optimizing and improving incontinence care so that nurses’ hands can focus more of their attention on social and tactile care.",
      "ru": "Глобальный директор по интеллектуальным решениям IQ Solutions в Essity рассказывает о нашей работе по оптимизации и совершенствованию ухода при недержании, благодаря которой медицинские сестры могут уделять больше внимания социальному и тактильному уходу.",
      "es": "Nuestro director global de marca en IQ Solutions habla sobre el trabajo que realizamos para optimizar y mejorar el cuidado de la incontinencia a fin de que las manos de los enfermeros puedan dedicar más atención al cuidado social y táctil."
    },
    "text20": {
      "en": "deaths could result by 2050 from inaction toward the threat of antimicrobial resistance.",
      "ru": "человек могут погибнуть к 2050 году в результате бездействия на фоне угрозы развития резистентности к противомикробным препаратам.",
      "es": "de muertes podrían ocurrir para 2050 si no se toman medidas contra la amenaza de la resistencia antimicrobiana."
    },
    "text22": {
      "en": "https://amr-review.org/",
      "ru": "https://amr-review.org/",
      "es": "https://amr-review.org/"
    },
    "text21": {
      "en": "AMR Review Organisation",
      "ru": "Организация по оценке уровня резистентности к противомикробным препаратам",
      "es": "Organización de Revisión de RAM (en inglés, AMR Review Organisation)"
    },
    "text24": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text23": {
      "en": "2/5 cases of HAI’s are caused by cross-infection via the hands of healthcare workers.",
      "ru": "Примерно 2 из 5 случаев внутрибольничных инфекций являются результатом перекрестного инфицирования через руки медицинских работников.",
      "es": "2 de cada 5 casos de infecciones relacionadas con la atención médica son causadas por infección cruzada a través de las manos de los trabajadores de la salud."
    },
    "text15": {
      "en": "Almost 70% of HAI’s can be prevented, and the most effective and cost-efficient way is to improve hand hygiene among healthcare workers.",
      "ru": "До 70 % внутрибольничных инфекций можно предотвратить, и наиболее эффективный и экономически выгодный способ это сделать — повысить уровень гигиены рук среди медицинских работников.",
      "es": "Casi el 70 % de las infecciones relacionadas con la atención médica puede prevenirse, y la forma más eficaz y rentable de hacerlo es mejorar la higiene de manos entre los trabajadores de la salud."
    },
    "text14": {
      "en": "Jenny Logenius explaining our Virtual Reality training tool, developed in collaboration with leading hand hygiene experts.",
      "ru": "Дженни Лоджениус описывает наш учебный инструмент виртуальной реальности, разработанный в сотрудничестве с ведущими специалистами в области гигиены рук.",
      "es": "Jenny Logenius explica nuestra herramienta de capacitación con realidad virtual, desarrollada en colaboración con expertos destacados en higiene de manos."
    },
    "text17": {
      "en": "https://www.essity.com/company/essentials-initiative/stories-and-videos/hand-to-hand/the-critical-need-for-hygiene-to-prevent-infections-and-spread-of-antibiotic-resistance/",
      "ru": "https://www.essity.com/company/essentials-initiative/stories-and-videos/hand-to-hand/the-critical-need-for-hygiene-to-prevent-infections-and-spread-of-antibiotic-resistance/",
      "es": "https://www.essity.com/company/essentials-initiative/stories-and-videos/hand-to-hand/the-critical-need-for-hygiene-to-prevent-infections-and-spread-of-antibiotic-resistance/"
    },
    "text16": {
      "en": "RD Scott",
      "ru": "RD Scott",
      "es": "RD Scott"
    },
    "text19": {
      "en": "10 million",
      "ru": "10 миллионов",
      "es": "10 millones"
    },
    "text18": {
      "en": "We are proud sponsors of the German Women’s Beach Volleyball Team. Our Medical Innovations team have developed LeukoTape, a market leader in sports taping that physiotherapists’ hands apply to athletes to provide support and stability to muscles and joints.",
      "ru": "Мы горды быть спонсором женской сборной Германии по пляжному волейболу. Наша группа медицинских инноваций разработала LeukoTape – продукт, который является лидером продаж на рынке спортивных лент. Такие ленты физиотерапевт заботливо накладывает на тело спортсмена, чтобы обеспечить поддержку и стабильность мышц и суставов.",
      "es": "Somos patrocinadores orgullosos del equipo femenino de voleibol de playa de Alemania. Nuestro equipo de Innovaciones Médicas ha desarrollado LeukoTape, un líder en el mercado de vendaje deportivo que las manos de los fisioterapeutas aplican a los deportistas para brindar apoyo y estabilidad a los músculos y articulaciones."
    },
    "text31": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text30": {},
    "text3": {
      "en": "For those working as healthcare practitioners, their hands have the power to heal other humans.",
      "ru": "Руки медицинских работников могут исцелять других людей.",
      "es": "Para aquellos que trabajan como profesionales de la salud, las manos tienen el poder de curar a otras personas."
    },
    "text11": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text4": {
      "en": "For those working as healthcare practitioners, their hands have the power to heal other humans. Be it in Eastern or Western philosophy, their purpose remains the same: using their hands and minds to help other humans to improve or maintain health. Be it in the tactile touch used to reassure patients, the surgeries they perform, medicine they administer and treatments they apply, steady, caring hands remain at the center of their toolkit.",
      "ru": "Руки медицинских работников могут исцелять других людей. И в западных странах, и в восточных странах цель одна и та же: использовать руки и знания, чтобы помогать другим людям улучшать или сохранять здоровье. Будь то прикосновения для успокоения пациентов, введение лекарств или операции и процедуры, которые выполняют медицинские работники, заботливые руки и уверенные движения остаются их главным инструментом.",
      "es": "Para aquellos que trabajan como profesionales de la salud, las manos tienen el poder de curar a otras personas. Ya sea en la filosofía oriental u occidental, el propósito es el mismo: usar las manos y la mente para ayudar a otras personas a mejorar o conservar la salud. Ya sea en el tacto que utilizan para calmar a los pacientes, las cirugías que realizan, los medicamentos que administran o los tratamientos que aplican, las manos firmes y cuidadosas siempre son la herramienta fundamental."
    },
    "text10": {
      "en": "of contaminants are removed when hand washing with soap.",
      "ru": "загрязняющих веществ удаляются при мытье рук с мылом.",
      "es": "de los contaminantes se elimina al lavarse las manos con jabón."
    },
    "text32": {
      "en": "Power of Hands by Essity",
      "ru": "«Сила рук», Essity",
      "es": "El poder de las manos de Essity"
    },
    "text1": {
      "en": "Power of Hands in Healthcare",
      "ru": "«Сила рук в здравоохранении»",
      "es": "El poder de las manos en la atención médica"
    },
    "text13": {
      "en": "3sddMUklGo4",
      "ru": "3sddMUklGo4",
      "es": "3sddMUklGo4"
    },
    "text2": {
      "en": "power-of-hands-in-healthcare",
      "ru": "power-of-hands-in-healthcare",
      "es": "el-poder-de-las-manos-en-la-atencion-medica"
    },
    "text12": {
      "en": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/ensuring-a-good-foundation-for-the-next-generation.html?search-highlight=handwashing+with+soap+removes+90%25+of+contaminants",
      "ru": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/ensuring-a-good-foundation-for-the-next-generation.html?search-highlight=handwashing+with+soap+removes+90%25+of+contaminants",
      "es": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/ensuring-a-good-foundation-for-the-next-generation.html?search-highlight=handwashing+with+soap+removes+90%25+of+contaminants"
    },
    "text7": {
      "en": "The hands of I Gusti Mangku Sasak, a 76-year-old Balinese healer and Usada Bali practitioner, holding his medical books.",
      "ru": "Густи Мангку Сасак, 76-летний балийский целитель и практик Усада Бали, держит в руках одну из своих «книг» с медицинскими записями.",
      "es": "Las manos de Gusti Mangku Sasak, un curandero balinés y profesional de Usada Bali de 76 años, sostienen sus libros de medicina."
    },
    "text8": {
      "en": "Hands are an important part of the care that medical practitioners provide. However, up to 2 in 5 cases of healthcare-associated infections are caused by cross-infection via the hands of healthcare workers (<a href=\"https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). For this reason, hand hygiene will play a crucial role in combatting the effects of antimicrobial resistance. If no action is taken, AMR could be responsible for 10 million deaths by 2050 (<a href=\"https://amr-review.org/\" rel=\"noopener noreferrer\" target=\"blank\">AMR Review</a>). This is why our Tork team has developed a VR Hand Hygiene education training game to teach healthcare practitioners how, when and why to effectively wash hands whilst treating a patient.",
      "ru": "Медицинская помощь, оказываемая практикующими врачами, во многим зависит от того, насколько умелы их руки. При этом примерно 2 из 5 случаев внутрибольничных инфекций вызваны перекрестным заражением и переносятся на руках медицинских работников(<a href=\"https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). По этой причине гигиена рук будет играть решающую роль в борьбе с последствиями возникновения резистентности к противомикробным препаратам. Если не будет принято никаких мер, к 2050 году резистентность к противомикробным препаратам может стать причиной гибели 10 миллионов человек (<a href=\"https://amr-review.org/\" rel=\"noopener noreferrer\" target=\"blank\">AMR Review</a>). Именно поэтому Tork разработала обучающую игру VR Hand Hygiene. Ее цель – научить практикующих врачей, как, когда и почему следует мыть руки во время лечения пациента.",
      "es": "Las manos son una parte importante de la atención que brindan los profesionales de la medicina. Sin embargo, hasta 2 de cada 5 casos de infecciones asociadas con la atención médica son causadas por infección cruzada a través de las manos de los trabajadores de la salud (<a href=\"https://www.essity.com/company/essentials-initiative/events/world-hand-hygiene-day/\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). Por este motivo, la higiene de manos cumplirá un papel fundamental en combatir los efectos de la resistencia antimicrobiana (RAM). Si no se toman medidas, la RAM podría ser responsable de 10 millones de muertes para 2050 (<a href=\"https://amr-review.org/\" rel=\"noopener noreferrer\" target=\"blank\">AMR Review</a>). Es por ese motivo que nuestro equipo de Tork desarrolló un juego de capacitación con realidad virtual sobre la educación para la higiene de manos con el objetivo de enseñarles a los profesionales de la salud cómo, cuándo y por qué lavarse las manos de manera eficaz mientras se trata a un paciente."
    },
    "text5": {
      "en": "Bali, Indonesia 2018",
      "ru": "Бали, Индонезия, 2018 г.",
      "es": "Bali, Indonesia 2018"
    },
    "text6": {
      "en": "Gusti Mangku Sasak, a 76-year-old Balinese healer and Usada Bali practitioner, holds his medical books. The books are collections of thin palm leaves tied together with string, engraved with medicinal recipes, diagnoses, and other information in Kawi, an old Javanese language that is still used in traditional arts and during ceremonies. <br /> <br />Text and Photo by Malin Fezehai",
      "ru": "Густи Мангку Сасак, 76-летний балийский целитель и практик Усада Бали, ведет архив медицинских записей. Архив представляет собой «книги» из тонких пальмовых листьев, перевязанных бечевкой, с вырезанными на них лекарственными рецептами, диагнозами и другой информацией на языке Кави, древнем яванском языке, который до сих пор используется в традиционном искусстве и во время церемоний. <br /> <br />Текст и фото Малин Фезехай",
      "es": "Gusti Mangku Sasak, un curandero balinés y profesional de Usada Bali de 76 años, sostiene sus libros de medicina. Los libros son colecciones de delgadas hojas de palmera atadas con una cuerda, impresas con recetas medicinales, diagnósticos y demás información en kawi, un antiguo idioma javanés que aún se utiliza en las artes tradicionales y durante las ceremonias. <br /> <br />Texto y fotografía de Malin Fezehai"
    },
    "text9": {
      "en": "90%",
      "ru": "90 %",
      "es": "El 90 %"
    }
  },
  "story1": {
    "text26": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text25": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text28": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text27": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text29": {},
    "text20": {
      "en": "JJ7LwLueAf8",
      "ru": "ovXhAnOuLoE",
      "es": "gOE0XvDbtgk"
    },
    "text22": {
      "en": "We need stronger global cooperation for research and development of new pharmaceuticals that are produced in a sustainable way, and reach all who need them. Access to clean water and hygiene has to increase all over the world to decrease infections and the need for antibiotics.",
      "ru": "Нам нужно более тесное глобальное сотрудничество в области исследований и разработок новых фармацевтических препаратов с учётом экологических требований и норм; мы должны стремиться обеспечить ими всех, кто в них нуждается. Чистую воду и гигиенические процедуры необходимо делать более доступными по всему миру. Так мы снизим количество инфекций и потребность в антибиотиках.",
      "es": "Necesitamos una cooperación mundial más sólida para la investigación y el desarrollo de nuevos medicamentos que se produzcan de manera sustentable y que lleguen a todos aquellos que los necesiten. El acceso al agua potable y a la higiene debe mejorar en todo el mundo para reducir las infecciones y la necesidad de antibióticos."
    },
    "text21": {},
    "text24": {
      "en": "Power of Hands launch exhibition at Fotografiska Stockholm, where attendees discuss the importance of hygiene and health.",
      "ru": "«Сила рук» и музей Fotografiska в Стокгольме открывают выставку, посетители которой смогут обсудить важность гигиены и здоровья.",
      "es": "La exposición de lanzamiento de “El poder de las manos” en Fotografiska, Estocolmo, donde los participantes conversan sobre la importancia de la higiene y la salud."
    },
    "text23": {
      "en": "— Otto Cars, Senior Professor of Infectious Diseases, Uppsala University",
      "ru": "— Отто Карс, старший научный сотрудник кафедры инфекционных болезней Уппсальского университета",
      "es": "— Otto Cars, profesor sénior de Enfermedades Infecciosas, Uppsala University"
    },
    "text15": {
      "en": "— Joséphine Edwall Björklund, Senior Vice President of Corporate Communications at Essity.",
      "ru": "- Жозефин Эдвалл Бьёрклунд, старший вице-президент по корпоративным коммуникациям компании Essity.",
      "es": "— Joséphine Edwall Björklund, vicepresidenta sénior de Comunicación Corporativa de Essity."
    },
    "text14": {
      "en": "Power of Hands celebrates one of humanity's most important tools. If we use our hands in the right way, we can strengthen and help each other – we can save lives. But hands also cause the spread of infection, if they are not kept clean. Essity spreads knowledge about the importance of good hand-hygiene that can prevent infections.",
      "ru": "«Сила рук» призвана отдать дань уважения одному из важнейших инструментов, доступных человечеству. Если мы воспользуемся им правильно, мы можем стать сильнее, мы можем помогать друг другу, мы можем спасать жизни! Но руки также переносят инфекции, если не содержать их в чистоте. Essity распространяет знания о важности гигиены рук для предотвращения инфекций.",
      "es": "“El poder de las manos” celebra una de las herramientas más importantes de la humanidad. Si usamos las manos de la manera correcta, podemos fortalecernos y ayudarnos mutuamente. Podemos salvar vidas. Pero las manos también pueden transmitir una infección si no se mantienen limpias. Essity difunde el conocimiento sobre la importancia de una buena higiene de manos que pueda prevenir infecciones."
    },
    "text17": {
      "en": "Cela's hands dressing her sister Rosa.",
      "ru": "Рука Селы одевает ее сестру Розу.",
      "es": "Las manos de Cela vistiendo a su hermana Rosa"
    },
    "text16": {
      "en": "Boys embracing, Tarawa, Republic of Kiribati.",
      "ru": "Обнимающиеся мальчики, Тарава, Республика Кирибати.",
      "es": "Los niños se abrazan, Tarawa, República de Kiribati."
    },
    "text19": {
      "en": "RaJSMOhdBXY",
      "ru": "3VtDRXMoSyw",
      "es": "TWIjZ1wdYTI"
    },
    "text18": {},
    "text30": {
      "en": "Power of Hands by Essity",
      "ru": "«Сила рук», Essity",
      "es": "El poder de las manos de Essity"
    },
    "text3": {
      "en": "Together with Fotografiska, our exhibition Power of Hands shows Malin Fezehai’s empathetic photography of how humans use hands as tools for living around the world.",
      "ru": "Наша совместная с Fotografiska выставка «Сила рук» демонстрирует посетителям наполненное эмпатией фотоискусство Малин Фезехай. Лейтмотив выставки – то, как люди по всему миру используют руки в качестве инструмента, создающего условия для жизни.",
      "es": "Junto con Fotografiska, nuestra exposición “El poder de las manos” muestra la fotografía empática de Malin Fezehai que retrata la manera en que los seres humanos usan las manos como herramientas para su vida cotidiana en todo el mundo."
    },
    "text11": {
      "en": "My hands are my tools in the creation of the image, but they also mean a lot when I portray people. How the expression of their body is captured is just as important as the facial expression. Because it can communicate so many different things, like tenderness, pride, joy, or sadness. I work in many different realities and to me the human connection is the most important.",
      "ru": "Мои собственные руки – это инструмент создания образа, но они также играют важную роль, когда я создаю портрет человека. То, как запечатлеваются выразительные возможности тела, так же важно, как и выражение лица. Ведь языком тела можно передавать столь разнообразный спектр переживаний – нежность, гордость, радость, печаль. Я описываю различные реалии, среди которых связь между людьми является самой важной.",
      "es": "Mis manos son mis herramientas en la creación de imágenes, pero también significan mucho cuando retrato personas. Cómo se captura la expresión del cuerpo es tan importante como la expresión del rostro. Porque puede comunicar tantas cosas diferentes, como ternura, orgullo, alegría o tristeza. Trabajo en muchas realidades distintas y, a mi criterio, la conexión humana es la más importante."
    },
    "text4": {
      "en": "The Power of Hands",
      "ru": "Сила рук",
      "es": "El poder de las manos"
    },
    "text10": {
      "en": "Hands washing out the eyes of a protester after being exposed to teargas on the day of the Inauguration of President Trump.",
      "ru": "Промывают глаза протестующему после того, как он подвергся воздействию слезоточивого газа в день инаугурации президента Трампа.",
      "es": "Las manos lavan los ojos de un manifestante luego de haber sido expuesto a gases lacrimógenos el día de la toma de posesión del presidente Trump."
    },
    "text1": {
      "en": "Power of Hands at Fotografiska",
      "ru": "Выставка «Сила рук» в музее Fotografiska",
      "es": "El poder de las manos en Fotografiska"
    },
    "text13": {
      "en": "Behind the scenes of Power of Hands with visual reporter, Malin Fezehai.",
      "ru": "За кулисами выставки «Сила рук» с Малин Фезехай, фотографом-документалистом.",
      "es": "Detrás de escena de “El poder de las manos” con la reportera gráfica, Malin Fezehai."
    },
    "text2": {
      "en": "power-of-hands-at-fotografiska",
      "ru": "power-of-hands-at-fotografiska",
      "es": "el-poder-de-las-manos-en-fotografiska"
    },
    "text12": {
      "en": "— Malin Fezehai",
      "ru": "— Малин Фезехай",
      "es": "— Malin Fezehai"
    },
    "text7": {
      "en": "School girls looking out to sea in Galle Port, Sri Lanka.",
      "ru": "Школьницы смотрят на море в порту Галле, Шри-Ланка.",
      "es": "Las alumnas miran el mar en Galle Port, Sri Lanka."
    },
    "text8": {
      "en": "Malin Fezehai is a young, award-winning Swedish-Eritrean photographer based in New York, that's been working in over 30 countries in Asia, the Middle East, Africa, and America, often with a focus on cultures in change. Fezehai's empathetic photography shows us how much of what is deeply human constantly unites us.",
      "ru": "Малин Фезехай – молодой, отмеченный наградами фотограф шведско-эритрейского происхождения из Нью-Йорка, которая работала в более чем 30 странах Азии, Ближнего Востока, Африки и Америки, уделяя особое внимание культурам в условиях перемен. Наполненная эмпатией фотография Фезехай показывает нам, как много глубоко человеческого постоянно объединяет всех нас.",
      "es": "Malin Fezehai, una joven fotógrafa sueca-eritrea galardonada que vive en Nueva York, ha trabajado en más de 30 países de Asia, Medio Oriente, África y América, generalmente, centrándose en las culturas en proceso de cambio. La fotografía empática de Fezehai nos muestra cuánto de lo profundamente humano nos une de manera constante."
    },
    "text5": {
      "en": "Together with Fotografiska, our exhibition Power of Hands shows Malin Fezehai’s empathetic photography of how people all around the world live their lives. In frozen moments, we experience hands as the main actors – both in their banality and wonder. These amazing tools enrich our lives with purpose and allow us to reach out to each other.",
      "ru": "Наша совместная с Fotografiska выставка «Сила рук» демонстрирует посетителям наполненное эмпатией фотоискусство Малин Фезехай, посвященное людям из разных стран и их жизни. Искусство запечатления момента позволяет нам воспринимать руки в качестве главных героев – это может быть и тривиально, и необычно одновременно. Эти удивительные инструменты обогащают нашу жизнь, помогая воплощать цели и мечты и позволяя тянуться друг к другу.",
      "es": "Junto con Fotografiska, nuestra exposición “El poder de las manos” muestra la fotografía empática de Malin Fezehai que retrata la manera en que las personas en todo el mundo viven su vida. En los momentos congelados, experimentamos las manos como actores principales, tanto en lo banal como en lo asombroso. Estas maravillosas herramientas enriquecen nuestras vidas con propósito y nos permiten acercarnos a los demás."
    },
    "text6": {
      "en": "At the same time, hands can be tools for harm, whether or not we are aware of their impact. In this vein, we seek to provide insight into the role that hands can play in breaking barriers to well-being throughout humanity.",
      "ru": "В то же время руки могут навредить, независимо от того, осознаем ли мы, что наносим вред. В этом ключе мы стремимся дать представление о той роли, которую могут сыграть руки в преодолении барьеров на пути к благополучию всего человечества.",
      "es": "Al mismo tiempo, las manos pueden ser herramientas para hacer daño, seamos o no conscientes de su impacto. En este sentido, buscamos brindar conocimiento sobre el papel que pueden cumplir las manos en derribar las barreras que impiden el bienestar en toda la humanidad."
    },
    "text9": {
      "en": "— Lisa Hydén, exhibition producer at Fotografiska International.",
      "ru": "– Лиза Хайден, продюсер выставки в Fotografiska International.",
      "es": "— Lisa Hydén, productora de exposición en Fotografiska International."
    }
  },
  "report": {
    "text3": {
      "en": "With this report, Essity and WSSCC address opportunities and challenges throughout the human life cycle, highlighting new research, best practices and possible solutions. It shows the societal implications of prioritizing hygiene and health in decision making, whether by a policy maker, a care professional or an individual.",
      "ru": "В этом отчете Essity и WSSCC рассматривают возможности и сложные задачи, возникающие на протяжении всего жизненного цикла человека, уделяя внимание новым исследованиям, лучшим практикам и возможным решениям. Он описывает социальные последствия включения задач гигиены и здоровья в число приоритетных при принятии решений, будь то со стороны политика, медицинского работника или каждого из нас.",
      "es": "Con este reporte, Essity y WSSCC abordan las oportunidades y los desafíos a lo largo del ciclo de vida humano, destacando las nuevas investigaciones, las mejores prácticas y las posibles soluciones. Muestra las consecuencias sociales de dar prioridad a la higiene y la salud en la toma de decisiones, ya sea por parte de un legislador, un profesional de la salud o de un individuo."
    },
    "text11": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text4": {
      "en": "We want more people to talk, to act, to contribute so we see a change that people's well-being will be improved over the years.",
      "ru": "Мы хотим, чтобы больше людей говорили об этом, действовали, вносили личный вклад и мы бы увидели постепенное улучшение ситуации.",
      "es": "Aspiramos a que más personas hablen, actúen, contribuyan para que veamos un cambio que indique que el bienestar de las personas mejorará a lo largo de los años."
    },
    "text10": {
      "en": "<a href=\"http://reports.essity.com/2018-19/hygiene-and-health-report/en/\" target=\"_blank\" rel=\"noopener noreferrer\">Tap here</a> to access the full results and previous surveys and reports. ",
      "ru": "<a href=\"http://reports.essity.com/2018-19/hygiene-and-health-report/en/\" target=\"_blank\" rel=\"noopener noreferrer\">Нажмите здесь,</a> чтобы получить доступ к полным результатам предыдущим опросам и отчетам.",
      "es": "<a href=\"http://reports.essity.com/2018-19/hygiene-and-health-report/en/\" target=\"_blank\" rel=\"noopener noreferrer\">Haga clic aquí</a> para acceder a los resultados completos y a encuestas y reportes previos."
    },
    "text1": {
      "en": "‘Personal Well-being – Key to Public Progress’",
      "ru": "«Индивидуальное здоровье – залог общественного прогресса»",
      "es": "“Bienestar personal: clave para el progreso social”"
    },
    "text2": {
      "en": "In May 2018, Essity and United Nation's entity Water Supply and Sanitation Collaborative Council (WSSCC) launched the sixth edition of the Hygiene and Health Report. Hygiene and health are essential catalysts for solving several global societal issues stated in the UN Sustainable Development Goals.",
      "ru": "В мае 2018 года Essity и Совместный совет по вопросам водоснабжения и санитарии ООН (WSSCC) выпустили шестой выпуск Отчета по гигиене и здоровью. Гигиена и здоровье являются важными катализаторами решения ряда глобальных проблем общества, упомянутых в Целях устойчивого развития ООН.",
      "es": "En mayo de 2018, Essity y el Consejo de Colaboración para el Abastecimiento de Agua y el Saneamiento (WSSCC, por sus siglas en inglés), una entidad de las Naciones Unidas, publicaron la sexta edición del Reporte sobre Higiene y Salud. La higiene y la salud son motores esenciales para resolver varios de los problemas sociales mundiales establecidos en los Objetivos de Desarrollo Sustentable de las Naciones Unidas."
    },
    "text7": {
      "en": "20 countries surveyed",
      "ru": "Опрос проходил в 20 странах",
      "es": "20 países encuestados"
    },
    "text8": {
      "en": "Our partnerships and report are part of the Essity Essentials Initiative, where through regular surveys, events and education, Essity contributes to a more knowledge-based public dialogue that drives positive change for people everywhere.",
      "ru": "Партнерские отношения, в которых мы состоим, и составление отчета – это часть инициативы Essity Essentials, в рамках которой компания проводит регулярные опросы, формирует отчеты и организует мероприятия, внося свой вклад в построение более информативного диалога с общественностью, что способствует позитивным изменениям в жизни людей по всему миру.",
      "es": "Nuestras alianzas y el reporte forman parte de Essentials Initiative de Essity, una iniciativa en la que, a través de encuestas, eventos y educación frecuentes, Essity contribuye a un diálogo público basado en un mayor conocimiento, que impulsa cambios positivos para las personas en todas partes."
    },
    "text5": {
      "en": "- Joséphine Edwall Björklund, SVP Group Communications at Essity",
      "ru": "- Жозефин Эдвалл Бьёрклунд, старший вице-президент по корпоративным коммуникациям компании Essity.",
      "es": "- Joséphine Edwall Björklund, Vicepresidenta Sénior de Comunicación Corporativa de Essity"
    },
    "text6": {
      "en": "As a leading global hygiene and health company, Essity has unique knowledge and insights to drive global dialogue about the strong connection between hygiene, health and well-being. We collaborate with partners using our diverse perspectives, competencies and resources to raise awareness, develop standards and influence regulations in relation to health and hygiene.",
      "ru": "Являясь одним из мировых лидеров по производству товаров для гигиены и здоровья, компания Essity обладает обширной базой знаний и данных, подтверждающих существование тесной связи между гигиеной, здоровьем и благополучием. В сотрудничестве с нашими партнерами мы занимаемся просвещением населения, разработкой стандартов и стремимся вносить необходимые изменения в законодательство в области гигиены и здравоохранения.",
      "es": "Al ser una empresa líder en higiene y salud a nivel global, Essity cuenta con conocimiento único y experiencia para poder impulsar un diálogo global acerca de la estrecha conexión que existe entre la higiene, la salud y el bienestar. Colaboramos con socios, utilizando nuestras diversas perspectivas, competencias y recursos para crear conciencia, desarrollar estándares e influir en las normas relacionadas con la salud y la higiene."
    },
    "text9": {
      "en": "Insights for the report were collected from 20 countries through surveys, research and fieldwork. Tap here to access the full report and survey results.",
      "ru": "Данные для отчета были собраны в 20 странах посредством проведения опросов, исследований и работы на местах. Полный отчет и результаты опросов доступны здесь.",
      "es": "Se obtuvieron perspectivas para el reporte de 20 países a través de encuestas, investigación y trabajo de campo. Haga clic aquí para acceder a los resultados de las encuestas y el reporte completo."
    }
  },
  "about": {
    "text25": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text20": {
      "en": "Essity is a leading global hygiene and health company dedicated to improving well-being through our products and solutions, essentials for everyday life. The name Essity stems from the words ‘essentials’ and ‘necessities’. Sales are conducted in approximately 150 countries under the leading global brands TENA and Tork, and other strong brands, such as JOBST, Leukoplast, Libero, Libresse, Lotus, Nosotras, Saba, Tempo, Vinda, and Zewa.",
      "ru": "Essity — один из мировых лидеров по производству товаров для гигиены и здоровья, целью деятельности которого является повышение благополучия жизни людей при помощи продукции и решений, необходимых в повседневной жизни. Название Essity составлено из слов «essentials» и «necessities» (предметы первой необходимости). Продажи осуществляются примерно в 150 странах под ведущими международными брендами TENA и Tork, а также другими признанными брендами, такими как JOBST, Leukoplast, Libero, Libresse, Lotus, Nosotras, Saba, Tempo, Vinda и Zewa.",
      "es": "Essity es una empresa líder mundial en higiene y salud dedicada a mejorar el bienestar a través de nuestros productos y soluciones, esenciales para la vida cotidiana. El nombre Essity deriva de las palabras inglesas “essentials” (productos esenciales) y “necessities” (necesidades). Comercializamos nuestros productos en alrededor de 150 países bajo muchas marcas sólidas, incluidas las marcas líderes a nivel mundial TENA y Tork, y otras como Jobst, Leukoplast, Libero, Libresse, Lotus, Nosotras, Saba, Tempo, Vinda y Zewa."
    },
    "text22": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text21": {
      "en": "Our vision is; Dedicated to improving well-being through leading hygiene and health solutions. Essity has about 48,000 employees and net sales in 2018 amounted to approximately SEK 118.5bn (EUR 11.5bn). The business operations are based on a sustainable business model with a focus on value creation for people and nature.",
      "ru": "Мы стремимся повышать благополучие жтзни людей посредством внедрения передовых решений в области гигиены и здоровья. В Essity работает около 48 000 сотрудников; объем продаж компании в 2018 году составил почти 118,5 миллиарда шведских крон (11,5 миллиарда евро). Наша коммерческая деятельность основана на бизнес-модели устойчивого развития, и мы уделяем основное внимание созданию ценности как для людей, так и для природы.",
      "es": "Nuestra visión: Mejorar el bienestar a través de soluciones líderes en higiene y salud. Essity tiene alrededor de 48 mil empleados y las ventas netas en 2018 ascendieron a aproximadamente 118 mil 500 millones de SEK (11 mil 500 millones de EUR). Las operaciones comerciales se basan en un modelo de negocio sustentable centrado en la creación de valor para las personas y la naturaleza."
    },
    "text24": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text23": {
      "en": "Photo: Malin Fezehai",
      "ru": "Фото: Малин Фезехай",
      "es": "Fotografía: Malin Fezehai"
    },
    "text15": {
      "en": "We want to be the most interesting place to show photography in the world. We operate large, modern exhibition spaces, featuring several shows concurrently. We are open from early morning until late at night.",
      "ru": "Мы хотим быть самым интересным местом для демонстрации фоторабот в мире. Музей обеспечивает функционирование больших выставочных площадей, оснащенных по последнему слову техники, где одновременно проходят несколько выставок. Мы открыты с раннего утра до позднего вечера.",
      "es": "Aspiramos a ser el lugar más interesante para exponer fotografías en el mundo. Utilizamos amplios espacios modernos de exposición y realizamos varias muestras de manera simultánea. Abrimos temprano a la mañana y cerramos tarde a la noche."
    },
    "text14": {
      "en": "Unlike traditional museums, it is not Fotografiska’s role to display its own collection. Instead, our exhibitions are developed directly with the artists, estates, collections, and galleries or curated around a central theme.",
      "ru": "В отличие от традиционных музеев, задача Fotografiska не ограничивается показом собственных фондов. Вместо этого мы готовим выставки вместе с художниками, местными сообществами, коллекционерами и галереями или курируем работу вокруг основной темы.",
      "es": "A diferencia de los museos tradicionales, la función de Fotografiska no consiste en exponer su propia colección. En su lugar, nuestras exposiciones se desarrollan directamente con los artistas, patrimonios, colecciones y galerías, o giran en torno a una temática central."
    },
    "text17": {
      "en": "After nine successful years of fine-tuning our business, we are going global. During 2019 and 2020 we are in preparations to open in New York and London.",
      "ru": "После девяти успешных лет «тонкой настройки» своей деятельности мы выходим на международный уровень. В 2019 и 2020 годах мы планируем открыть филиалы в Нью-Йорке и Лондоне.",
      "es": "Después de nueve años exitosos de perfeccionar nuestro negocio, nos estamos expandiendo al mundo. Durante 2019 y 2020 estaremos con los preparativos para inaugurar en Nueva York y en Londres."
    },
    "text16": {
      "en": "School girls looking out to sea in Galle Port.",
      "ru": "Школьницы смотрят на море в порту Галле, Шри-Ланка.",
      "es": "Las alumnas miran el mar en Galle Port."
    },
    "text19": {
      "en": "About Essity",
      "ru": "О компании Essity",
      "es": "Acerca de Essity"
    },
    "text18": {
      "en": "Fotografiska For Life is our platform for projects that aim to raise awareness about social issues, using photography as a medium to inspire action. We show the work of the world’s foremost photographers and photojournalists in a context that generates public impact and inspires our audience to learn more about issues that are close to our hearts. Seminars and talks with leading experts on the subjects that we highlight are integral parts of the projects and important forums for debate and change.",
      "ru": "«Fotografiska ради жизни» – это наша платформа для проектов, которые направлены на повышение осведомленности о социальных проблемах через фотографию как средство, побуждающее к действию. Мы демонстрируем работы ведущих мировых фотографов и фотожурналистов в контексте, который позволяет воздействовать на общественное сознание и побуждает нашу аудиторию узнавать больше о проблемах, которые близки нашим сердцам. Семинары и беседы с ведущими экспертами по темам, на освещении которых мы специализируемся, являются неотъемлемой частью проектов и важными общественными площадками для дискуссий и воплощению изменений.",
      "es": "Fotografiska For Life es nuestra plataforma para aquellos proyectos que apunten a crear conciencia sobre temas sociales, utilizando la fotografía como medio para inspirar acciones. Exponemos el trabajo de los fotógrafos y reporteros gráficos más importantes del mundo en un contexto que genera impacto público e inspira a nuestra audiencia a aprender más sobre temas muy preciados para nosotros. Los seminarios y charlas con expertos líderes en los temas que destacamos constituyen una parte integral de los proyectos y son foros importantes para el debate y el intercambio."
    },
    "text3": {
      "en": "In a journey across four continents, we meet people that, through their hands express their identity, show tenderness, pride, solemnness, and connection to the world around them.",
      "ru": "Путешествуя по четырем континентам, мы встречаем людей, которые с помощью своих рук проявляют индивидуальность, нежность, гордость, величие и связь с окружающим миром.",
      "es": "En un viaje a través de cuatro continentes, conocimos personas que, a través de las manos, expresan su identidad, muestran ternura, orgullo, solemnidad y conexión con el mundo que los rodea."
    },
    "text11": {
      "en": "Rarámuri women in Chihuahua, Mexico, have hand-made an indigenous style of dress a means of fighting assimilation.",
      "ru": "Женщины племени рарамури в штате Чиуауа, Мексика, собственными руками создали туземный стиль одежды в качестве средства борьбы с ассимиляцией.",
      "es": "Las mujeres rarámuris en Chihuahua, México, han convertido el estilo de vestimenta indígena hecha a mano en una forma de luchar contra la asimilación."
    },
    "text4": {
      "en": "Malin Fezehai, photographer and visual reporter at her exhibition in Fotografiska, Stockholm.",
      "ru": "Малин Фезехай, фотограф-документалист на своей выставке в музее Fotografiska, Стокгольм.",
      "es": "Malin Fezehai, fotógrafa y reportera gráfica en su exposición en Fotografiska, Estocolmo."
    },
    "text10": {
      "en": "Our previous year’s exhibition, Hand to Hand, premiered on September 27, 2018, with Swedish photojournalists Paul Hansen and Åsa Sjöström. We hope you will join us in exploring the stories and insights from our Essentials Initiative.",
      "ru": "Наша последняя выставка «Рука об руку» была открыта 27 сентября 2018 года. На ней представлены работы шведских фотожурналистов Пола Хансена и Асы Шёстрём. Мы надеемся, что вы присоединитесь к нам в изучении историй и идей нашей инициативы Essity Essentials.",
      "es": "Nuestra exposición del año pasado, “Mano a mano”, inaugurada el 27 de septiembre de 2018, con los reporteros gráficos suecos Paul Hansen y Åsa Sjöström. Esperamos que se una a nosotros para explorar las historias y los conocimientos de nuestra Essentials Initiative."
    },
    "text1": {
      "en": "The Exhibition",
      "ru": "Выставка",
      "es": "La exposición"
    },
    "text13": {
      "en": "Fotografiska is the largest photography museum in the world. Beyond celebrating photography, we offer inclusive spaces for conversation and community. We believe in creating a common ground that invites everyone in, where our guests can listen to lectures, stay for dinner, or meet friends. Our mission is to inspire a more conscious world.",
      "ru": "Fotografiska – крупнейший музей фотографии в мире. Помимо выставок фотографий, мы организуем инклюзивные пространства для общения. Мы верим в успех нашей общественной площадки и приглашаем всех желающих. Здесь наши гости смогут послушать лекции, остаться на ужин или встретиться с друзьями. Наша миссия – вдохновлять на создание более безопасного и рационального мира.",
      "es": "Fotografiska es el museo de fotografía más grande del mundo. Además de celebrar la fotografía, ofrecemos espacios inclusivos para la conversación y la comunidad. Creemos en crear un punto de encuentro que invite a todos a participar, donde nuestros invitados puedan escuchar charlas, quedarse a cenar o encontrarse con amigos. Nuestra misión es servir de inspiración hacia un mundo más consciente."
    },
    "text2": {
      "en": "Malin Fezehai, photographer, visual reporter, and World Press Photo Award recipient is currently exhibiting at Fotografiska as part of the Fotografiska For Life exhibition Power of Hands in association with Essity. Fezehai’s images draw attention to the important role hands play as physical extensions of our minds. In the gestures of hands, much of our identity, intentions, and desires, our innermost thoughts and moods are reflected.",
      "ru": "Малин Фезехай, фотограф-документалист и лауреат премии World Press Photo, в настоящее время выставляется в музее Fotografiska в рамках выставки «Fotografiska ради жизни. Сила рук», которая проводится совместно с Essity. Работы Фезехай привлекают внимание к той важной роли, которую играют руки как физическое продолжение нашего разума. В жестах рук отражается большая часть нашей личности, намерений и желаний, наши самые сокровенные мысли и настроения.",
      "es": "Malin Fezehai, fotógrafa, reportera gráfica y ganadora del World Press Photo Award actualmente expone su obra en Fotografiska como parte de la exposición “El poder de las manos” de Fotografiska For Life en asociación con Essity. Las imágenes de Fezehai ponen de manifiesto la importancia de la función que cumplen las manos como extensiones físicas de nuestra mente. En los gestos de las manos se refleja gran parte de nuestra identidad, intenciones y deseos, así como nuestros pensamientos más íntimos y estados de ánimo."
    },
    "text12": {
      "en": "About Fotografiska",
      "ru": "О музее Fotografiska",
      "es": "Acerca de Fotografiska"
    },
    "text7": {
      "en": "Muazzez Kocek being embraced by her daughter Kader in their home in Kuşköy, Turkey.",
      "ru": "Муаззез Кочек в объятиях своей дочери Кадер в их доме в Кушкой, Турция.",
      "es": "Muazzez Kocek, abrazada por su hija Kader en su hogar en Kuşköy, Turquía."
    },
    "text8": {
      "en": "Otto Cars, Senior Professor of Infectious Diseases at Uppsala University and founder of ReAct, renowned both in Sweden and internationally for his work on how to tackle the threat of antibiotic resistance, has also provided Fotografiska with insights and facts to the ‘Power of Hands’ exhibition.",
      "ru": "Отто Карс, старший научный сотрудник кафедры инфекционных болезней Уппсальского университета и основатель глобальной сети ReAct, известный как в Швеции, так и во всем мире своей работой по борьбе с угрозой резистентности к антибиотикам, предоставил Fotografiska информацию и факты для выставки «Сила рук».",
      "es": "Otto Cars, profesor sénior de Enfermedades Infecciosas en la Uppsala University y fundador de ReAct, de renombre tanto en Suecia como a nivel mundial por su trabajo sobre cómo abordar la amenaza de la resistencia a los antibióticos, también ha aportado conocimientos y datos a Fotografiska para la exposición “El poder de las manos”."
    },
    "text5": {
      "en": "Essity and Fotografiska have stated a long-term mission to raise awareness about global hygiene and health, and how this fundamental issue affects the well-being of people around the world. Not just physically, but also how hygiene and health are related to basic human needs and rights, such as education and gender equality, and society’s general development. The story is told through photography in conjunction with Essity’s expertise and long-spanning experience within this area. Through our collaboration, we aim to inspire a more conscious and sustainable world.",
      "ru": "Essity и Fotografiska заявили о своей долгосрочной миссии по повышению осведомленности о глобальной гигиене и здоровье, а также о том, как эта фундаментальная проблема влияет на благосостояние людей по всему миру. И не только в физическом плане. Гигиена и здоровье связаны с основными человеческими потребностями, правами и понятиями, такими как образование, гендерное равенство, и развитие общества в целом. История рассказывается через искусство фотографии в сочетании с многолетним опытом Essity в этой области. Развивая наше сотрудничество, мы стремимся повысить сознательность людей и сделать мир более безопасным и рациональным.",
      "es": "Essity y Fotografiska se han propuesto un objetivo a largo plazo: crear conciencia acerca de la salud y la higiene global y acerca de cómo este problema fundamental afecta el bienestar de las personas en todo el mundo. No solo físicamente, sino también cómo se relacionan la higiene y la salud con las necesidades esenciales y los derechos humanos básicos, como por ejemplo la igualdad de género y la educación y el desarrollo de la sociedad en general. La historia se narra a través de fotografías conjuntamente con la experiencia y la gran trayectoria de Essity en esta área. Deseamos que nuestra colaboración sirva de inspiración hacia un mundo más consciente y sustentable."
    },
    "text6": {
      "en": "The latest exhibition, ‘Power of Hands with Swedish-Eritrean visual reporter, Malin Fezehai was launched on November 29, 2019, and runs until February 09, 2020.",
      "ru": "Последняя выставка – «Сила рук», на которой демонстрируются работы фотографа-документалиста шведско-эритрейского происхождения Малин Фезехай, открылась 29 ноября 2019 года и продлится до 9 февраля 2020 года.",
      "es": "La exposición más reciente, “El poder de las manos” con la reportera gráfica sueco-eritrea, Malin Fezehai, fue inaugurada el 29 de noviembre de 2019 y se extiende hasta el 9 de febrero de 2020."
    },
    "text9": {
      "en": "More info on his work to be found on <a href=\"https://www.reactgroup.org/antibiotic-resistance\" rel=\"noopener noreferrer\" target=\"_blank\">here.</a>",
      "ru": "Более подробную информацию о его работе можно найти по ссылке <a href=\"https://www.reactgroup.org/antibiotic-resistance\" rel=\"noopener noreferrer\" target=\"_blank\">here.</a>",
      "es": "Para obtener más información sobre su trabajo, ingrese a <a href=\"https://www.reactgroup.org/antibiotic-resistance\" rel=\"noopener noreferrer\" target=\"_blank\">here.</a>"
    }
  },
  "story2": {
    "text26": {
      "en": "Menstruation evokes discomfort because of its association to sexuality and reproduction; shame because of girls’ inability to manage it effectively and exclusion because of its association with impurity. This hinders girls’ abilities to carry out everyday activities, weakens their self-esteem and self-confidence.",
      "ru": "Менструация вызывает неприятие из-за ее связи с сексуальной и репродуктивной функцией, стыдом из-за неспособности девочек эффективно справляться с особенностями данного периода, а также отторжение из-за ассоциаций с непристойностью. Это мешает девочкам полноценно заниматься ежедневными делами, снижает их самооценку и уверенность в себе.",
      "es": "La menstruación provoca incomodidad debido a que se la asocia con la sexualidad y la reproducción, genera vergüenza entre las niñas por su incapacidad de manejarla eficazmente, y exclusión debido a su asociación con lo impuro. Esto impide que las niñas lleven adelante sus actividades cotidianas, y les debilita la autoestima y la confianza en sí mismas."
    },
    "text25": {
      "en": "feel uncomfortable in social situations if they have unwashed hands ",
      "ru": "людей чувствуют себя некомфортно в ситуациях социального взаимодействия, если у них немытые руки",
      "es": "se siente incómodo en situaciones sociales si tiene las manos sucias."
    },
    "text28": {
      "en": "We believe it is important that male hands are comfortable with care-giving roles in everyday life. This includes stigmatized tasks like buying pads from the supermarket.",
      "ru": "Мы считаем очень важным, чтобы мужским рукам было комфортно выполнять действия, связанные с повседневным уходом, в том числе такие стигматизированные задачи, как, например, покупка прокладок в супермаркете.",
      "es": "Creemos que es importante que las manos de los hombres se sientan cómodas cumpliendo funciones de cuidado en las actividades cotidianas. Esto incluye actividades estigmatizadas como comprar toallas femeninas en el supermercado."
    },
    "text27": {
      "en": "– Dr. Venkatraman Chandra-Mouli, physician in adolescent sexual & reproductive health",
      "ru": "– Доктор Венкатраман Чандра-Мули, специалист в области сексуального и репродуктивного здоровья подростков",
      "es": "– Dr. Venkatraman Chandra-Mouli, médico especialista en salud sexual y reproductiva en adolescentes"
    },
    "text29": {
      "en": "10%",
      "ru": "10 %",
      "es": "El 10 %"
    },
    "text40": {
      "en": "Power of Hands by Essity",
      "ru": "«Сила рук», Essity",
      "es": "El poder de las manos de Essity"
    },
    "text20": {
      "en": "3/5",
      "ru": "3/5",
      "es": "60 %"
    },
    "text42": {
      "en": "Hands for Caring",
      "ru": "Заботливые руки",
      "es": "Manos para cuidar"
    },
    "text41": {
      "en": "16%",
      "ru": "16 %",
      "es": "El 16 %"
    },
    "text22": {
      "en": "23%",
      "ru": "23 %",
      "es": "El 23 %"
    },
    "text21": {
      "en": "argue about the cleaning and hygiene roles our hands perform in the home.",
      "ru": "спорят о роли гигиенических процедур, которые мы выполняем руками в своем доме.",
      "es": "discuten sobre las funciones de limpieza e higiene que cumplen nuestras manos en el hogar."
    },
    "text24": {
      "en": "71%",
      "ru": "71 %",
      "es": "El 71 %"
    },
    "text23": {
      "en": "would wash their hands more often if they knew it had a positive impact on others.",
      "ru": "мыли бы руки чаще, если бы знали, что это положительным образом сказывается на других.",
      "es": "se lavaría las manos con más frecuencia si supiera que genera un efecto positivo en los demás."
    },
    "text15": {
      "en": "k5gPAPIiUHc",
      "ru": "F_fQ-oaC5g4",
      "es": "IzR8GR_pRJM"
    },
    "text37": {},
    "text14": {
      "en": "In conducting research into hand hygiene attitudes and behaviors, we found that being more conscious of the cleanliness of your hands makes people happier and more affectionate.",
      "ru": "Проводя исследование гигиенических установок и поведения, мы обнаружили, что более осознанное отношение к чистоте рук делает людей счастливее и нежнее.",
      "es": "Al llevar a cabo una investigación sobre las actitudes y comportamientos relacionados con la higiene de manos, descubrimos que ser más conscientes de la limpieza de las manos hace más felices y más afectuosas a las personas."
    },
    "text36": {
      "en": "When our hands care for the wounds of children and the elderly, the current dressings can cause pain and even damage to sensitive skin. We have developed a dressing that does not tug at skin when removed.",
      "ru": "Когда мы обрабатываем раны детей и пожилых людей, перевязочные материалы могут вызвать боль и даже повредить чувствительную кожу. Мы разработали повязку, которая не тянет кожу, когда ее снимают.",
      "es": "Cuando nuestras manos cuidan las heridas de niños y ancianos, las gasas que se utilizan actualmente pueden causar dolor e incluso, dañar la piel sensible. Hemos desarrollado un tipo de gasa que no arranca la piel al quitarla."
    },
    "text17": {
      "en": "would hug their family more if they knew they had washed their hands.",
      "ru": "чаще обнимали бы близких, если бы знали, что их руки чисты.",
      "es": "abrazaría más a sus familiares si supiera que se lavaron las manos."
    },
    "text39": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text16": {
      "en": "As it stands today, hygiene is unfairly gendered. The way we split hygiene tasks amongst the hands in our homes is not always equal. To draw attention to this, our brand Zewa constructed an obstacle course to demonstrate the impact inequality has.",
      "ru": "Сегодня культура гигиены несправедливо разделена по половому признаку. Распределение гигиенических задач между руками в наших домах не всегда одинаково. Чтобы привлечь внимание к проблеме, наш бренд Zewa построил полосу препятствий, чтобы продемонстрировать влияние неравенства.",
      "es": "En su forma actual, la higiene se divide injustamente según el género. La manera en que repartimos las tareas de higiene entre las manos de nuestros hogares no siempre es equitativa. Para visibilizar este tema, nuestra marca Zewa construyó una pista con obstáculos para demostrar el impacto que tiene la desigualdad."
    },
    "text38": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text19": {
      "en": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf",
      "ru": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf",
      "es": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf"
    },
    "text18": {
      "en": "Essity, 2018, Power of Hands Survey",
      "ru": "Essity, 2018 г., опрос «Сила рук»",
      "es": "Essity, 2018, encuesta “El poder de las manos”"
    },
    "text31": {
      "en": "Plan International",
      "ru": "Международная программа",
      "es": "Plan internacional"
    },
    "text30": {
      "en": "of girls in the UK can’t afford to buy menstrual products.",
      "ru": "британских девушек не могут позволить себе гигиеническую продукцию во время менструаций.",
      "es": "de las niñas en el Reino Unido no puede afrontar el costo de los productos menstruales."
    },
    "text3": {
      "en": "The hands we use to care for our immediate family and community will raise children, build homes, and give to those less fortunate.",
      "ru": "Руки как инструмент ухода за близкими и заботы об окружающих людях; руки, растящие детей; руки, которые строят дома и помогают тем, кому в жизни повезло меньше.",
      "es": "Las manos que usamos para cuidar a nuestra familia y comunidad criarán niños, construirán hogares y ayudarán a quienes menos tienen."
    },
    "text11": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text33": {
      "en": "ELs5eHfepis",
      "ru": "n68XjwVzl58",
      "es": "Naq4xb44zmA"
    },
    "text4": {
      "en": "It’s in the everyday that we find our most fundamental purpose as humans: acts of compassion and kindness. The hands we use to care for our immediate family and community will raise children, build homes, and give to those less fortunate. The worlds that our hands construct around us form the building blocks of who we are.",
      "ru": "Именно в повседневности мы, люди, обнаруживаем свою фундаментальную цель: проявление сострадания и доброты. Руки как инструмент ухода за близкими и заботы об окружающих людях; руки, растящие детей; руки, которые строят дома и помогают тем, кому в жизни повезло меньше. Миры, создаваемые нашими руками.",
      "es": "Es en la vida cotidiana donde encontramos nuestro propósito más trascendental como seres humanos: los actos de compasión y amabilidad. Las manos que usamos para cuidar a nuestra familia y comunidad criarán niños, construirán hogares y ayudarán a quienes menos tienen. Los mundos que nuestras manos construyan alrededor de nosotros constituyen los cimientos de quiénes somos."
    },
    "text10": {
      "en": "of people feel uncomfortable in social situations if they have unwashed hands",
      "ru": "людей чувствуют себя некомфортно в ситуациях социального взаимодействия, если у них немытые руки",
      "es": "de las personas se sienten incómodas en situaciones sociales si tienen las manos sucias"
    },
    "text32": {
      "en": "https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma",
      "ru": "https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma",
      "es": "https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma"
    },
    "text1": {
      "en": "Power of Hands in Everyday Life",
      "ru": "«Сила рук в повседневной жизни»",
      "es": "El poder de las manos en la vida cotidiana"
    },
    "text13": {
      "en": "9lk5o41nSus",
      "ru": "n68XjwVzl58",
      "es": "N6DIuFoPMtA"
    },
    "text35": {
      "en": "Emuq-GTBwuI",
      "ru": "Emuq-GTBwuI",
      "es": "h0pi326HbCU"
    },
    "text2": {
      "en": "power-of-hands-in-everyday-life",
      "ru": "power-of-hands-in-everyday-life",
      "es": "el-poder-de-las-manos-en-la-vida-cotidiana"
    },
    "text12": {
      "en": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-menstruation",
      "ru": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-menstruation",
      "es": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-menstruation"
    },
    "text34": {
      "en": "Managing one’s own hygiene is an important source of independence and self-esteem. Hundreds of hands came together with the Red Cross to distribute hygiene packs to the homeless community in Paris.",
      "ru": "Возможность обеспечить поддержание собственной гигиены – важный источник обретения независимости и самоуважения. Сотни рук вместе с Красным Крестом раздали гигиенические наборы бездомным в Париже.",
      "es": "Manejar la higiene de uno mismo es una fuente importante de independencia y autoestima. Cientos de manos se unieron a la Cruz Roja para distribuir kits de higiene a la comunidad de personas en situación de calle de París."
    },
    "text7": {
      "en": "Two young actresses drawing together at a film-set in Harlem.",
      "ru": "Две молодые актрисы рисуют вместе на съемочной площадке в Гарлеме.",
      "es": "Dos jóvenes actrices dibujan juntas en un estudio de filmación en Harlem."
    },
    "text8": {
      "en": "The ability to manage one’s own hygiene is central to our self-esteem and how we interact with others. We become conscious of hygiene worry and its importance to well-being only when hygiene becomes inaccessible. In the UK, 1/10 girls can’t afford menstrual products (<a href=\"https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma\" rel=\"noopener noreferrer\" target=\"_blank\">Plan International</a>). Not being able to use one’s hands to effectively care for one’s own body has a significant impact on self-esteem.",
      "ru": "Способность соблюдать личную гигиену играет центральную роль в нашей самооценке и в том, как мы взаимодействуем с другими людьми. Мы начинаем думать о гигиене и ее важности для благополучия и комфорта только тогда, когда гигиена становится недоступной. В Великобритании 1 из 10 девушек не могут позволить себе приобрести гигиенические средства во время менструации (<a href=\"https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma\" rel=\"noopener noreferrer\" target=\"_blank\">Plan International</a>). Невозможность использовать руки для эффективного ухода за телом серьезным образом снижает самооценку.",
      "es": "La capacidad de manejar la higiene propia es fundamental para nuestra autoestima y la manera en que interactuamos con los demás. Tomamos conciencia sobre el problema de la higiene y su importancia para el bienestar solamente cuando la higiene se vuelve inaccesible. En el Reino Unido, 1 de cada 10 niñas no puede afrontar el costo de los productos menstruales (<a href=\"https://plan-uk.org/media-centre/plan-international-uks-research-on-period-poverty-and-stigma\" rel=\"noopener noreferrer\" target=\"_blank\">Plan International</a>). No poder usar las propias manos para cuidar eficazmente el propio cuerpo tiene un impacto significativo en la autoestima."
    },
    "text5": {
      "en": "New York, USA 2011",
      "ru": "Нью-Йорк, США, 2011 г.",
      "es": "Nueva York, Estados Unidos 2011"
    },
    "text6": {
      "en": "Two girls interacting between scenes on a film set in Harlem, New York. The girl on the left was playing the role of a young woman that was going blind, while the girl on the right is 80% blind in real life and was a guide to the actress on how to perform in front of the cameras. <br /> <br />Text and Photo by Malin Fezehai",
      "ru": "Две девушки в перерыве между сценами на съемочной площадке в Гарлеме, Нью-Йорк. Актриса слева играла роль молодой девушки, теряющей зрение; девушка справа на 80 % слепа в реальной жизни и дает коллеге наставления о том, как вести себя перед камерами.<br /> <br />Текст и фото Малин Фезехай",
      "es": "Dos niñas interactúan detrás de escena en un estudio de filmación en Harlem, Nueva York. La niña a la izquierda representaba a una mujer joven que se estaba quedando ciega, mientras que la niña a la derecha es 80 % ciega en la vida real y guiaba a la actriz sobre cómo actuar frente a las cámaras. <br /> <br />Texto y fotografía de Malin Fezehai"
    },
    "text9": {
      "en": "71%",
      "ru": "71 %",
      "es": "El 71 %"
    }
  },
  "story3": {
    "text26": {
      "en": "The Mannheim Children’s Festival invites children to dance, sing, explore and get creative. Essity employee hands made frames to add the finishing touches on the childrens’ creations.",
      "ru": "Мангеймский детский фестиваль приглашает детей танцевать, петь, исследовать мир и творчески развиваться. Сотрудники Essity своими руками сделали рамки, добавив завершающие штрихи к творческим работам детей.",
      "es": "El Festival de niños de Mannheim invita a los niños a bailar, cantar, explorar y usar la creatividad. Las manos del empleado de Essity construyeron marcos para agregar los toques finales a las creaciones de los niños."
    },
    "text25": {
      "en": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-children",
      "ru": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-children",
      "es": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-children"
    },
    "text28": {
      "en": "National Institute for Education Evaluation (2014)",
      "ru": "Национальный институт оценки качества образования (2014 г.)",
      "es": "Instituto Nacional para la Evaluación de la Educación (en inglés, National Institute for Education Evaluation), 2014"
    },
    "text27": {
      "en": "1/5 schools in Mexico City and Chihuahua lack adequate washrooms.",
      "ru": "В каждой пятой школе в Мехико и Чиуауа нет нормального туалета.",
      "es": "1 de cada 5 escuelas en Ciudad de México y Chihuahua no cuentan con baños adecuados."
    },
    "text29": {
      "en": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/",
      "ru": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/",
      "es": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/"
    },
    "text40": {
      "en": "Power of Hands by Essity",
      "ru": "«Сила рук», Essity",
      "es": "El poder de las manos de Essity"
    },
    "text20": {
      "en": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/investing-in-hygiene-for-life.html",
      "ru": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/investing-in-hygiene-for-life.html",
      "es": "http://reports.essity.com/2018-19/hygiene-and-health-report/en/early-adopters/investing-in-hygiene-for-life.html"
    },
    "text22": {
      "en": "15%",
      "ru": "15 %",
      "es": "El 15 %"
    },
    "text21": {
      "en": "Children’s hands touch and explore in order to understand the world. Photojournalist Åsa Sjöström followed the hands of children in a UK primary school.",
      "ru": "Детские руки исследуют мир с помощью прикосновений. Фотожурналист Аса Шёстрём наблюдала за движениями детских рук в одной из начальных школ Соединенного Королевства.",
      "es": "Las manos de los niños tocan y exploran para comprender el mundo. La reportera gráfica Åsa Sjöström observó las manos de los niños en una escuela primaria del Reino Unido."
    },
    "text24": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text23": {
      "en": "of parents have prevented their children from visiting a playground due to concerns about hygiene",
      "ru": "родителей запретили своим детям посещать детские площадки из-за проблем с гигиеной",
      "es": "de los padres ha evitado que sus hijos vayan a un parque infantil debido a preocupaciones relacionadas con la higiene."
    },
    "text15": {
      "en": "of UK primary school teachers rate the hygiene habits (washing their hands and cleaning themselves properly) of children as poor and say the problem is getting worse.",
      "ru": "британских учителей начальных классов оценивают гигиенические навыки и привычки детей (мытье рук и правильный уход за телом) на достаочно низком уровне и считают, что проблема усугубляется.",
      "es": "maestros de escuela primaria en el Reino Unido califican los hábitos de higiene (lavarse las manos y asearse adecuadamente) de los niños como deficientes y afirman que el problema está empeorando."
    },
    "text37": {
      "en": "Photo: Åsa Sjöström",
      "ru": "Фото: Аса Шёстрём",
      "es": "Fotografía: Åsa Sjöström"
    },
    "text14": {
      "en": "6/10",
      "ru": "6/10",
      "es": "60 %"
    },
    "text36": {},
    "text17": {
      "en": "https://schoolhygieneessentials.co.uk/",
      "ru": "https://schoolhygieneessentials.co.uk/",
      "es": "https://schoolhygieneessentials.co.uk/"
    },
    "text39": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text16": {
      "en": "Essity and YouGov",
      "ru": "Essity и YouGov",
      "es": "Essity y YouGov"
    },
    "text38": {
      "en": "Photo: Åsa Sjöström",
      "ru": "Фото: Аса Шёстрём",
      "es": "Fotografía: Åsa Sjöström"
    },
    "text19": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text18": {
      "en": "Hand hygiene education programs in preschools can result in a reduction in absentee rates of between 7-13%.",
      "ru": "Программы обучения гигиене рук в дошкольных учреждениях могут привести к сокращению числа отсутствующих на занятиях вследствие болезни на 7-13 %.",
      "es": "Los programas de educación para la higiene de manos en instituciones preescolares pueden reducir los índices de ausentismo entre 7 y 13 %."
    },
    "text31": {
      "en": "Together with UNICEF, Essity brands Tork and Saba have formed a partnership to improve hand and menstrual hygiene education and attitudes in Mexican schools.",
      "ru": "Совместно с ЮНИСЕФ Tork и Saba (бренды Essity) оформили партнерство, целью которого является совершенствование обучающих программ и формирование надлежащих установок в области гигиены рук и менструальной гигиены в мексиканских школах.",
      "es": "Junto con UNICEF, las marcas de Essity, Tork y Saba, han formado una alianza para mejorar la educación y las actitudes relacionadas con la higiene de manos y la higiene menstrual en las escuelas mexicanas."
    },
    "text30": {
      "en": "Bmm2Ta1KRUM",
      "ru": "Bmm2Ta1KRUM",
      "es": "plZ_wzJb2yE"
    },
    "text3": {
      "en": "As children, our hands are used to construct and destroy, to touch and discover.",
      "ru": "В детстве мы созидаем и разрушаем, прикасаемся и делаем открытия с помощью своих рук.",
      "es": "Cuando somos niños, usamos las manos para construir y destruir, para tocar y descubrir."
    },
    "text11": {
      "en": "https://www.unhcr.org/children-49c3646c1e8.html",
      "ru": "https://www.unhcr.org/children-49c3646c1e8.html",
      "es": "https://www.unhcr.org/children-49c3646c1e8.html"
    },
    "text33": {
      "en": "of girls and adolescents in Mexico say that during their period they would rather stay at home than go to school.",
      "ru": "девочек и девушек в Мексике говорят, что в этот период они скорее останутся дома, чем пойдут в школу.",
      "es": "de las niñas y adolescentes en México manifiesta que durante su periodo prefiere quedarse en casa a ir a la escuela."
    },
    "text4": {
      "en": "Play is a key component of the way we learn as children. Our hands are used to construct and destroy, to touch and discover. The barriers that we face as children at the hands of others at this stage are out of our control. The consequences, whether large or small, have the power to determine our educational outcomes and long-term well-being. As adults, it is our role to teach children how to take care of themselves and their hands so they can navigate the world with minimal interruption.",
      "ru": "Игра – ключевой компонент обучения в детстве. Мы используем руки, чтобы строить и разрушать, прикасаться и получать новые знания. Препятствия, с которыми мы сталкиваемся в детстве и которые созданы руками других людей на этом этапе, находятся вне нашего контроля. Последствия, незначительные и серьезные, могут определять эффективность обучения и наше здоровье в долгосрочной перспективе. Мы, уже будучи взрослыми, должны учить детей ответственно относиться к своему телу и чистоте рук, тем самым помогая им быстро ориентироваться в современном мире.",
      "es": "El juego es un componente clave de la forma en que aprendemos en la infancia. Usamos las manos para construir y destruir, para tocar y descubrir. Los obstáculos que enfrentamos de niños en manos de otros en esta etapa están fuera de nuestro control. Las consecuencias, ya sean grandes o pequeñas, tienen el poder de determinar nuestros resultados educativos y bienestar a largo plazo. Como adultos, es nuestra función enseñar a los niños cómo cuidarse y cómo cuidar sus manos para que puedan navegar el mundo con el mínimo de interrupciones."
    },
    "text10": {
      "en": "UNHCR",
      "ru": "Управление Верховного комиссара ООН по делам беженцев",
      "es": "ACNUR"
    },
    "text32": {
      "en": "43%",
      "ru": "43 %",
      "es": "El 43 %"
    },
    "text1": {
      "en": "Power of Hands at School",
      "ru": "«Сила рук в школе»",
      "es": "El poder de las manos en la escuela"
    },
    "text13": {
      "en": "Children playing a hand clapping game at Little Digmoor primary school. Captured by Åsa Sjöström as part of her exploration of children’s hands. ",
      "ru": "Дети играют в «ладушки» в начальной школе Литтл-Дигмур. Снято Асой Шёстрём в рамках ее исследования, посвященного детским ручкам.",
      "es": "Los niños de la escuela primaria Little Digmoor juegan un juego de palmas. Capturado por Åsa Sjöström como parte de su estudio de las manos de los niños."
    },
    "text35": {
      "en": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/",
      "ru": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/",
      "es": "https://www.essity.com/media/press-release/essity-and-unicef-in-mexico-strengthen-collaboration-on-hygiene-issues/0b22fc7c91dccd65/"
    },
    "text2": {
      "en": "power-of-hands-at-school",
      "ru": "power-of-hands-at-school",
      "es": "el-poder-de-las-manos-en-la-escuela"
    },
    "text12": {
      "en": "One of the first signs of independence in children is their ability to use their hands to take care of their own hygiene. This helps them participate in the world healthily and happily. Due to taboos and breakdowns in communication, these crucial lessons around washing hands and caring for one’s body are sometimes missed. In the UK, 60% of primary school teachers would rate the hygiene habits (washing their hands and cleaning themselves properly) of children as poor and say the problem is getting worse (<a href=\"https://schoolhygieneessentials.co.uk\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). This is having an impact on the amount of time teachers spend cleaning children up, taking time away from learning.",
      "ru": "Одним из первых признаков независимого поведения у детей является их способность использовать руки для выполнения гигиенических процедур. Это помогает им чувствовать себя частью мира, быть счастливыми и здоровыми. Из-за табу и социальных кризисных явлений эти важнейшие уроки – что надо мыть руки и ухаживать за собственным телом – иногда не получается выучить. В Великобритании 60 % учителей начальных классов оценивают гигиенические навыки и привычки детей на достаточно низком уровне (мытье рук и правильный уход за телом) и считают, что проблема усугубляется (<a href=\"https://schoolhygieneessentials.co.uk\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). Это увеличивает время, которое учителя тратят на то, чтобы привести ребенка в надлежащий вид в ущерб учебе.",
      "es": "Uno de los primeros signos de independencia en los niños es su capacidad de usar las manos para cuidar su propia higiene. Esto los ayuda a participar en el mundo de manera saludable y feliz. Debido a tabúes y problemas de comunicación, estas lecciones esenciales sobre el lavado de manos y el cuidado del propio cuerpo a veces se pierden. En el Reino Unido, el 60 % de los maestros de escuela primaria calificaría los hábitos de higiene (lavarse las manos y asearse adecuadamente) de los niños como deficientes y afirma que el problema está empeorando (<a href=\"https://schoolhygieneessentials.co.uk\" rel=\"noopener noreferrer\" target=\"blank\">Essity</a>). Esto impacta en la cantidad de tiempo que los maestros dedican a limpiar a los niños, lo que quita tiempo de aprendizaje."
    },
    "text34": {
      "en": "UNICEF",
      "ru": "ЮНИСЕФ",
      "es": "UNICEF"
    },
    "text7": {
      "en": "Preparing herself for the school day, Tasneem's hands hold the comb and carefully does her hair.",
      "ru": "Готовясь к учебному дню, Тасним держит в руках расческу и тщательно причесывает волосы.",
      "es": "Preparándose para el día de escuela, las manos de Tasneem sostienen el peine y cuidadosamente arreglan el cabello."
    },
    "text8": {
      "en": ">50%",
      "ru": ">50 %",
      "es": "Más del 50 %"
    },
    "text5": {
      "en": "Bekaa Valley, Lebanon 2015",
      "ru": "Долина Бекаа, Ливан, 2015 г.",
      "es": "Valle de la Becá, Líbano 2015"
    },
    "text6": {
      "en": "Fleeing violence in Syria, Tasneem and her family settled in an informal camp in the Bekaa Valley in Lebanon. As refugees living in Lebanon, she and her friends dreamed of going back to school, over two million Syrian refugee children were out of school in Lebanon in 2015. Fortunately, her dream came true when a school opened close her camp in 2015, and Tasneem was able to enroll. <br /> <br />Text and Photo by Malin Fezehai",
      "ru": "Спасаясь от насилия в Сирии, Тасним и ее семья поселились в неофициальном лагере в долине Бекаа. Будучи беженцами в Ливане, она и ее друзья мечтали вернуться в школу. Более двух миллионов сирийских детей-беженцев не посещали школу в Ливане в 2015 году. К счастью, ее мечта сбылась, когда в 2015 году рядом с лагерем открылась школа, и Тасним смогла в нее поступить. <br /> <br />Текст и фото Малин Фезехай",
      "es": "Huyendo de la violencia en Siria, Tasneem y su familia se establecieron en un campamento informal en el Valle de la Becá en Líbano. Como refugiados en Líbano, ella y sus amigos soñaban con volver a la escuela. Más de dos millones de niños sirios refugiados no estaban escolarizados en Líbano en 2015. Afortunadamente, su sueño se volvió realidad cuando una escuela abrió cerca de su campamento en 2015 y Tasneem pudo inscribirse. <br /> <br />Texto y fotografía de Malin Fezehai"
    },
    "text9": {
      "en": "of the world’s refugees are children",
      "ru": "беженцев в мире – это дети",
      "es": "de los refugiados en el mundo son niños"
    }
  },
  "essityxfotografiska": {
    "text3": {
      "en": "Stories",
      "ru": "Сюжеты",
      "es": "Historias"
    },
    "text4": {
      "en": "About",
      "ru": "О программе",
      "es": "Acerca de"
    },
    "text10": {
      "en": "Together with Fotografiska, Essity initiated a 3-year partnership to stimulate dialogue around important topics on hygiene and health.",
      "ru": "В течение трех лет Essity развивает партнерские отношения с музеем Fotografiska, поднимая социальные темы и способствуя более активному диалогу по важным вопросам гигиены и здоровья.",
      "es": "Junto con Fotografiska, Essity inició una alianza de tres años para estimular el diálogo sobre temas importantes de higiene y salud."
    },
    "text1": {
      "en": "Explore Story",
      "ru": "Ознакомиться с сюжетом",
      "es": "Explorar historia"
    },
    "text2": {
      "en": "View next story",
      "ru": "Следующий сюжет",
      "es": "Ver siguiente historia"
    },
    "text7": {
      "en": "the-report",
      "ru": "the-report",
      "es": "el-reporte"
    },
    "text8": {
      "en": "about",
      "ru": "about",
      "es": "acerca-de"
    },
    "text5": {
      "en": "The Report",
      "ru": "Отчет",
      "es": "El reporte"
    },
    "text6": {
      "en": "Share",
      "ru": "Поделиться",
      "es": "Compartir"
    },
    "text9": {
      "en": "We’re using cookies to give you the best experience possible of Essity.com. <a href=\"https://www.essity.com/terms-and-conditions/terms-and-conditions/\" rel=\"noopener noreferrer\" target=\"_blank\">Read more about the cookies we use and how to change your settings</a>",
      "ru": "Мы используем файлы cookie, чтобы вы получили наилучшие впечатления от посещения сайта Essity.com. <a href=\"https://www.essity.com/terms-and-conditions/terms-and-conditions/\" rel=\"noopener noreferrer\" target=\"_blank\"> Узнать больше о файлах cookie, которые мы используем, и о том, как изменить свои настройки</a>",
      "es": "Utilizamos cookies para brindarle la mejor experiencia posible en Essity.com. <a href=\"https://www.essity.com/terms-and-conditions/terms-and-conditions/\" rel=\"noopener noreferrer\" target=\"_blank\">Obtenga más información sobre las cookies que utilizamos y cómo cambiar sus configuraciones</a>"
    }
  },
  "story4": {
    "text26": {
      "en": "bU-rOycK4zo",
      "ru": "762a1oG9ccI",
      "es": "KDGtoDn7LFU"
    },
    "text25": {
      "en": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf",
      "ru": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf",
      "es": "https://www.essity.com/Images/GHD2018-Power-of-Hands-Survey-Results_tcm339-67207.pdf"
    },
    "text28": {
      "en": "Impact of a comprehensive workplace hand hygiene program on employer health care insurance claims and costs, absenteeism, and employee perceptions and practices.",
      "ru": "Влияние комплексной программы гигиены рук на рабочем месте на страховые требования и расходы работодателя по медицинскому страхованию и оплате больничных листов, а также на восприятие со стороны сотрудников и практику поддержания чистоты рук.",
      "es": "Un programa integral de higiene de manos en el lugar de trabajo tiene un impacto sobre las reclamaciones y costos del seguro de salud del empleador, el ausentismo y las percepciones y prácticas del empleado."
    },
    "text27": {
      "en": "In conducting research into hand hygiene attitudes and behaviors in fulltime workers, we found that covert displays of hand washing create trust and loyalty between colleagues and customers. ",
      "ru": "В ходе исследования установок и поведения в области гигиены рук у штатных сотрудников, мы обнаружили, что ненавязчивая демонстрация вымытых рук формирует более доверительные отношения и лояльность между коллегами и клиентами.",
      "es": "Al realizar una encuesta sobre actitudes y comportamientos relacionados con la higiene de manos en trabajadores de tiempo completo, descubrimos que las exposiciones encubiertas de lavado de manos crean confianza y lealtad entre colegas y clientes."
    },
    "text29": {},
    "text40": {
      "en": "Power of Hands by Essity",
      "ru": "«Сила рук», Essity",
      "es": "El poder de las manos de Essity"
    },
    "text20": {
      "en": "https://cdntorkprod.blob.core.windows.net/docs-c5/14/198014/original/tork-whitepaper-uk.pdf",
      "ru": "https://cdntorkprod.blob.core.windows.net/docs-c5/14/198014/original/tork-whitepaper-uk.pdf",
      "es": "https://cdntorkprod.blob.core.windows.net/docs-c5/14/198014/original/tork-whitepaper-uk.pdf"
    },
    "text22": {
      "en": "23%",
      "ru": "23 %",
      "es": "El 23 %"
    },
    "text21": {
      "en": "The food truck industry is a booming industry but concerns about hand hygiene are holding potential customers back. ",
      "ru": "Индустрия мобильных кафе – динамично развивающаяся отрасль, однако проблемы с гигиеной рук сдерживают потенциальных клиентов.",
      "es": "Las gastronetas son una industria en auge, pero las preocupaciones relacionadas con la higiene de manos están frenando a los clientes potenciales."
    },
    "text24": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text23": {
      "en": "of surveyed fulltime workers would be happier at work if they knew their colleagues washed their hands properly.",
      "ru": "опрошенных штатных сотрудников заявили, что чувствовали бы себя более довольными, если бы знали, что их коллеги моют руки надлежащим образом.",
      "es": "de los trabajadores de tiempo completo encuestados sería más feliz en el trabajo si supiera que sus colegas se lavaron las manos apropiadamente."
    },
    "text15": {
      "en": "of fulltime workers, want greater priority to be given to hand hygiene.",
      "ru": "штатных сотрудников хотели бы, чтобы гигиене рук уделялось больше внимания.",
      "es": "de los empleados de tiempo completo quiere que se le dé mayor prioridad a la higiene de manos."
    },
    "text37": {},
    "text14": {
      "en": "40%",
      "ru": "40 %",
      "es": "El 40 %"
    },
    "text36": {
      "en": "The littlest hands of Oakenholt Primary School helped our UK team with Tork’s 50th birthday by making paper cakes.",
      "ru": "Самые маленькие ручки – ручки учеников начальной школы Окенхольта – помогли нашей британской команде подготовиться к пятидесятилетию компании Tork. Дети сделали бумажные торты.",
      "es": "Las manos más pequeñas de la Oakenholt Primary School colaboraron con nuestro equipo del Reino Unido haciendo pasteles de papel para el quincuagésimo cumpleaños de Tork."
    },
    "text17": {
      "en": "https://www.essity.com/company/essentials-initiative/events/global-handwashing-day/why-clean-hands-should-be-at-the-heart-of-your-business/",
      "ru": "https://www.essity.com/company/essentials-initiative/events/global-handwashing-day/why-clean-hands-should-be-at-the-heart-of-your-business/",
      "es": "https://www.essity.com/company/essentials-initiative/events/global-handwashing-day/why-clean-hands-should-be-at-the-heart-of-your-business/"
    },
    "text39": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text16": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text38": {
      "en": "Photo: Essity",
      "ru": "Фото: Essity",
      "es": "Fotografía: Essity"
    },
    "text19": {
      "en": "(Arbogast, J. W. et al 2016)",
      "ru": "(Дж. В. Арбогаст и другие (Arbogast, J. W. et al), 2016 г.)",
      "es": "(Arbogast, J. W. et al 2016)"
    },
    "text18": {
      "en": "There could be a 20% reduction in the amount of sick leave taken if proper hand hygiene programs were implemented in the workplace. ",
      "ru": "Если на рабочем месте будут внедрены надлежащие программы гигиены рук, можно будет на 20 % сократить количество больничных.",
      "es": "Podría registrarse una disminución del 20 % en la cantidad de licencias por enfermedad si se implementaran programas de higiene de manos adecuados en el lugar de trabajo."
    },
    "text31": {
      "en": "29%",
      "ru": "29 %",
      "es": "El 29 %"
    },
    "text30": {},
    "text3": {
      "en": "From Monday to Friday, and sometimes through the weekend, our hands write books, operate machinery, sew garments and type emails.",
      "ru": "С понедельника по пятницу, а иногда и в выходные дни, наши руки пишут книги, управляют машинами, шьют одежду и печатают электронные письма.",
      "es": "De lunes a viernes, y a veces durante el fin de semana, nuestras manos escriben libros, operan máquinas, cosen prendas de vestir y escriben correos electrónicos."
    },
    "text11": {
      "en": "Centre for Disease Control and Prevention",
      "ru": "Центр по контролю и профилактике заболеваний",
      "es": "Centros para el Control y la Prevención de Enfermedades (en inglés, Centers for Disease Control and Prevention)"
    },
    "text33": {
      "en": "Essity",
      "ru": "Essity",
      "es": "Essity"
    },
    "text4": {
      "en": "Hands at work form an essential part of our identity. From Monday to Friday, and sometimes through the weekend, our hands write books, operate machinery, sew garments and type emails. Having a tangible understanding of the direct impact that your work has on other people’s lives drives greater happiness and job satisfaction. For some, meaningful work is about solving a problem people experience; for others, it’s about contributing to cultural expression in a way that reinforces identity.",
      "ru": "Руки за работой составляют существенную часть нашей идентичности. С понедельника по пятницу, а иногда и в выходные дни, наши руки пишут книги, управляют машинами, шьют одежду и печатают электронные письма. Осознание влияния, которое ваша работа напрямую оказывает на жизнь других людей, – залог большей удовлетворенности выполняемой работой. Для одних значимая работа – это решение проблем, с которыми сталкиваются люди; для других – форма культурного самовыражения, подчеркивающего идентичность.",
      "es": "Las manos en el trabajo forman una parte esencial de nuestra identidad. De lunes a viernes, y a veces durante el fin de semana, nuestras manos escriben libros, operan máquinas, cosen prendas de vestir y escriben correos electrónicos. Tener un entendimiento tangible del impacto directo que tiene el trabajo sobre la vida de otras personas genera una mayor felicidad y satisfacción en el trabajo. Para algunos, un trabajo significativo consiste en resolver un problema que experimentan las personas; para otros, se trata de contribuir a la expresión cultural de una manera que refuerce la identidad."
    },
    "text10": {
      "en": "workdays are lost each year in the US due to the Flu",
      "ru": "рабочих дней пропускается в США из-за гриппа",
      "es": "de días laborales se pierden cada año en los Estados Unidos debido a la gripe"
    },
    "text32": {
      "en": "of surveyed workers who avoid the toilets at work say they do so because they lack toilet paper and/or soap.",
      "ru": "опрошенных сотрудников, которые избегают использование туалета на работе, говорят, что они не делают этого, поскольку им не хватает туалетной бумаги и/или мыла.",
      "es": "de los trabajadores encuestados que evita usar el baño en el trabajo dice que lo hace porque les falta papel higiénico y/o jabón."
    },
    "text1": {
      "en": "Power of Hands at Work",
      "ru": "«Руки за работой»",
      "es": "El poder de las manos en el trabajo"
    },
    "text13": {
      "en": "Hands volunteering for climate action. Volunteers teaming up on a reforestation effort in Izta-Popo National Park in Mexico.",
      "ru": "Руки волонтеров в борьбе с изменением климата. Волонтеры объединились для восстановления лесов в Национальном парке Изта-Попо в Мексике.",
      "es": "Manos que trabajan como voluntarias para la acción por el cambio climático. Voluntarios que se unen en una campaña de reforestación en el Parque Nacional Izta-Popo en México."
    },
    "text35": {
      "en": "3rOI3puCrFw",
      "ru": "3rOI3puCrFw",
      "es": "3rOI3puCrFw"
    },
    "text2": {
      "en": "power-of-hands-at-work",
      "ru": "power-of-hands-at-work",
      "es": "el-poder-de-las-manos-en-el-trabajo"
    },
    "text12": {
      "en": "https://www.cdc.gov/niosh/topics/flu/activities.html",
      "ru": "https://www.cdc.gov/niosh/topics/flu/activities.html",
      "es": "https://www.cdc.gov/niosh/topics/flu/activities.html"
    },
    "text34": {
      "en": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-public-toilets",
      "ru": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-public-toilets",
      "es": "http://reports.essity.com/2018-19/essentials-initiative-survey/results.html#filter=.s-public-toilets"
    },
    "text7": {},
    "text8": {
      "en": "Each year in the U.S., 17 million workdays are lost to the flu, many of which could have been prevented through better hand hygiene. This is largely in part, due to the increasing prevalence of open-plan offices that facilitate greater interaction between employees. It’s unsurprising then that 40% of full-time workers want greater priority to be given to hand hygiene. For our Tork team, it is their mission to create education and professional hygiene solutions that make handwashing stations easy to maintain for cleaning staff and easy to use for everyone.",
      "ru": "Каждый год в США грипп отнимает 17 миллионов рабочих дней. Во многих случаях невыходы на работу можно было бы предотвратить, обеспечив надлежащую гигиену рук. Это в значительной степени объясняется ростом количества офисов с открытой планировкой, которая способствует более плотному взаимодействию между сотрудниками. Поэтому неудивительно, что 40 % штатных сотрудников хотели бы, чтобы гигиене рук уделялось больше внимания. Миссией команды Tork является разработка образовательных и профессиональных гигиенических решений, которые упрощают обслуживание общественных туалетов и повышают удобство их использования всеми сотрудниками.",
      "es": "Cada año, en los Estados Unidos, se pierden 17 millones de días laborales debido a gripes, muchas de las cuales se podrían haber prevenido a través de una mejor higiene de manos. Esto se debe, en gran medida, a la creciente prevalencia de oficinas abiertas que facilitan una mayor interacción entre los empleados. No resulta sorprendente, entonces, que el 40 % de los empleados de tiempo completo quiera que se le dé mayor prioridad a la higiene de manos. Nuestro equipo de Tork tiene como misión crear soluciones de educación e higiene profesional que hagan que los lugares para lavarse las manos sean fáciles de mantener para el personal de limpieza y fáciles de usar para todos."
    },
    "text5": {
      "en": "Bangkok, Thailand 2018",
      "ru": "Бангкок, Таиланд, 2018 г.",
      "es": "Bangkok, Tailandia 2018"
    },
    "text6": {
      "en": "At the Chinese opera in Bangkok, actors spend hours applying layers of makeup with skilled hands backstage, transforming into gods and goddesses, heroes and villains from Chinese folklore and mythology. The audience consists mostly of older Thai-Chinese some of whom take their grandchildren to connect with a cultural memory that has been passed down for generations. Like so much of Chinese opera throughout the world, the performances are a product of a large Chinese diaspora Thailand is the home of one of the world’s largest Chinese communities outside China. As the community ages and audiences dwindle, there is a fear that the art will die out, but that doesn’t deter the troupe’s manager, Tatchai Obthong, “We don’t perform for people, we perform for the gods,” Mr. Tatchai said. <br /> <br />Text and Photo by Malin Fezehai",
      "ru": "В китайской опере в Бангкоке артисты тратят несколько часов на грим и благодаря умелым рукам гримеров превращаются в богов и богинь, героев и злодеев из китайского фольклора и мифологии. Аудитория состоит в основном из пожилых тайских китайцев, некоторые из которых берут своих внуков, тем самым формируя связь с культурной памятью нации, транслируемой от поколения к поколению. Как и в большинстве китайских оперных театров во всем мире, спектакли китайской оперы в Таиланде являются «продуктом», создаваемым широкой местной китайской диаспорой. Таиланд служит домом одной из крупнейших в мире китайских общин за пределами Китая. По мере того как сообщество стареет и аудитория уменьшается, растет страх утратить данный вид искусства, но это не останавливает менеджера труппы Татчая Обтонга. “Мы выступаем не для людей, мы выступаем для богов”, – говорит г-н Татчай. <br /> <br />Текст и фото Малин Фезехай",
      "es": "En la ópera china de Bangkok, los actores dedican horas a aplicarse capas de maquillaje con manos habilidosas detrás del escenario, y se transforman en dioses y diosas, héroes y villanos del folclore y la mitología china. La audiencia está compuesta mayoritariamente por adultos mayores de origen tailandés-chino, algunos de los cuales llevan a sus nietos para que se conecten con una memoria cultural que se ha transmitido de generación en generación. Al igual que tantas óperas chinas en todo el mundo, las obras son producto de una importante diáspora china. Tailandia es el hogar de una de las comunidades chinas más extensas del mundo fuera de China. A medida que la comunidad envejece y la audiencia se reduce, existe el temor de que el arte desaparezca; sin embargo, eso no desalienta al director de la compañía, Tatchai Obthong: “No actuamos para la gente, actuamos para los dioses”, afirmó el Sr. Tatchai. <br /> <br />Texto y fotografía de Malin Fezehai"
    },
    "text9": {
      "en": "17 million",
      "ru": "17 миллионов",
      "es": "17 millones"
    }
  }
}

/* eslint-enable */
