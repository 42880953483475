// @flow
import * as React from 'react';

import styles from './grid.module.scss';

interface Props {
  customClass?: string;
  inner?: boolean,
  children: React.ChildrenArray<React.Element<any>> | React.Element<any>;
}

const Grid = ({ children, customClass, inner }: Props) => (
  <div className={`${styles.grid} grid-container ${customClass || ''} ${inner ? styles.inner : ''}`}>
    {children}
  </div>
);

export default Grid;
