/* global window, document */
// @flow
import React, { useCallback } from 'react';

import styles from './story-content.module.scss';

import Intro from './components/intro/intro.component';
import TextWithHeadline from './components/text-with-headline/text-with-headline.component';
import Text from './components/text/text.component';
import Quote from './components/quote/quote.component';
import Fact from './components/fact/fact.component';
import Image from './components/image/image.component';
import BigImage from './components/big-image/big-image.component';
import Video from './components/video/video.component';
import Share from './components/share/share.component';
import SquareVideo from './components/square-video/square-video.component';
import ImageSlider from './components/image-slider/image-slider.component';
import StatsSlider from './components/stats-slider/stats-slider.component';
import Hero from './components/hero/hero.component';
import Next from './components/next/next.component';
import GridContainer from '../grid/grid.component';

import { useWindowDimensions, useWindowScroll } from '../../util/hooks';

interface Props {
  storyId: string,
  isBlack?: boolean,
  content: Array<Object>,
}

const components = {
  textWithHeadline: TextWithHeadline,
  text: Text,
  quote: Quote,
  fact: Fact,
  image: Image,
  video: Video,
  squarevideo: SquareVideo,
  bigImage: BigImage,
  share: Share,
  imageSlider: ImageSlider,
  statsSlider: StatsSlider,
  intro: Intro,
  hero: Hero,
  next: Next,
  grid: null,
};

const renderComponent = (storyId) => ({ component, data }, index: number) => {
  if (component === 'grid' && data.content && data.content.length > 0) {
    return (
      <GridContainer
        key={`story-${storyId}-grid-${index}`}
        inner
      >
        {data.content.map(renderComponent(storyId))}
      </GridContainer>
    );
  }

  const Component = components[component];

  if (!Component) {
    return null;
  }

  const componentRender = (
    // $FlowFixMe
    <Component
      key={`story-${storyId}-${index}`}
      data={data}
      // isBlack={isBlack === true}
    />
  );

  return componentRender;
};

const StoryContent = ({
  storyId, content,
} : Props) => {
  const dimensions = useWindowDimensions();

  const onScroll = useCallback(() => {
    const animateItems = document.querySelectorAll('.scroll-animation-component');

    const scrollY = window.pageYOffset;

    const scrollYBtm = scrollY + dimensions.height;

    for (let i = 0; i < animateItems.length; i++) {
      const clientRect = animateItems[i].getBoundingClientRect();

      const itemY = clientRect.top + scrollY + (clientRect.height / 2);
      if (itemY < scrollYBtm) {
        animateItems[i].classList.add(styles.show);
      }
    }
  }, [dimensions.height]);

  useWindowScroll(onScroll);

  let heroData;

  const newContent = [...content];

  if (content.length > 0 && content[0].component === 'hero') {
    heroData = content[0].data;
    newContent.shift();
  }

  let nextData;

  if (content.length > 0 && content[content.length - 1].component === 'next') {
    nextData = content[content.length - 1].data;
    newContent.pop();
  }

  const contentRender = newContent.map(renderComponent(storyId)).reduce((acc, current) => {
    if (current !== null) {
      return [...acc, current];
    }
    return acc;
  }, []);

  return (
    <div
      className={`${styles.storyContent}`}
    >
      {heroData && (
        <Hero
          data={heroData}
        />
      )}
      <GridContainer>
        {contentRender}
      </GridContainer>
      {nextData && (
        <Next
          data={nextData}
        />
      )}
    </div>
  );
};

export default StoryContent;
