import images from '../images';

import copy from '../copy';

const content = [
  {
    component: 'hero',
    data: {
      src: images.hero.story2,
      mobileSrc: images.heroMobile.story2,
      title: copy.story2.text1,
      explore: copy.essityxfotografiska.text1,
      position: '2/5',
    },
  },
  {
    component: 'grid',
    data: {
      content: [
        {
          component: 'bigImage',
          data: {
            src: images.content.story2.img1,
            details: {
              copy: [copy.story2.text7],
            },
            noAnimation: true,
          },
        },
        {
          component: 'intro',
          data: {
            copy: [copy.story2.text6],
            noAnimation: true,
          },
        },
      ],
    },
  },
  {
    component: 'text',
    data: {
      title: copy.story2.text40,
      copy: [copy.story2.text4],
    },
  },
  {
    component: 'text',
    data: {
      copy: [copy.story2.text8],
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story2.text9,
      copy: [copy.story2.text10],
      linkText: copy.story2.text11,
      src: copy.story2.text12,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      note: copy.story2.text14,
      videoId: copy.story2.text13,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small right',
      note: copy.story2.text16,
      videoId: copy.story2.text15,
    },
  },
  {
    component: 'imageSlider',
    data: {
      id: 'story-2-image-slider-1',
      title: copy.story2.text42,
      image: images.content.story2.img2,
      credit: copy.story2.text38,
      linkText: copy.story2.text18,
      src: copy.story2.text19,
      items: [
        {
          key: 'story-2-image-slider-1-1',
          title: copy.story2.text20,
          text: copy.story2.text21,
        },
        {
          key: 'story-2-image-slider-1-2',
          title: copy.story2.text22,
          text: copy.story2.text23,
        },
        {
          key: 'story-2-image-slider-1-3',
          title: copy.story2.text24,
          text: copy.story2.text25,
        },
        {
          key: 'story-2-image-slider-1-4',
          title: copy.story2.text41,
          text: copy.story2.text17,
        },
      ],
    },
  },
  {
    component: 'quote',
    data: {
      text: copy.story2.text26,
      author: copy.story2.text27,
    },
  },
  {
    component: 'image',
    data: {
      type: 'landscape right',
      src: images.content.story2.img3,
      details: {
        copy: [copy.story2.text28],
      },
      credit: copy.story2.text39,
    },
  },
  {
    component: 'fact',
    data: {
      title: copy.story2.text29,
      copy: [copy.story2.text30],
      linkText: copy.story2.text31,
      src: copy.story2.text32,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small left',
      note: copy.story2.text34,
      videoId: copy.story2.text33,
    },
  },
  {
    component: 'video',
    data: {
      type: 'small right',
      note: copy.story2.text36,
      videoId: copy.story2.text35,
    },
  },
  {
    component: 'share',
    data: {
      share: copy.essityxfotografiska.text6,
    },
  },
  {
    component: 'next',
    data: {
      src: images.hero.story3,
      mobileSrc: images.heroMobile.story3,
      copy: copy.essityxfotografiska.text2,
      position: '3/5',
      nextTitle: copy.story3.text1,
      nextUrl: copy.story3.text2,
      nextId: 2,
    },
  },
];

export default content;
