/* global window, navigator */
// @flow

export const getDefaultLanguage = () => {
  const browserLanguage = window.navigator.userLanguage
    || window.navigator.language || navigator.language;

  let defaultLanguage;

  switch (browserLanguage) {
    case 'en-AU':
    case 'en-CA':
    case 'en-IN':
    case 'en-NZ':
    case 'en-ZA':
    case 'en-GB':
    case 'en-US':
    case 'en':
      defaultLanguage = 'en';
      break;
    // case 'fr-FR':
    // case 'fr':
    //   defaultLanguage = 'fr';
    //   break;
    // case 'de-DE':
    // case 'de':
    //   defaultLanguage = 'de';
    //   break;
    case 'es-AR':
    case 'es-BO':
    case 'es-CL':
    case 'es-CO':
    case 'es-CR':
    case 'es-DO':
    case 'es-EC':
    case 'es-SV':
    case 'es-GT':
    case 'es-HN':
    case 'es-MX':
    case 'es-NI':
    case 'es-PA':
    case 'es-PY':
    case 'es-PE':
    case 'es-PR':
    case 'es-ES':
    case 'es-US':
    case 'es-UY':
    case 'es-VE':
    case 'es':
      defaultLanguage = 'es';
      break;
    case 'ru-RU':
    case 'ru-MO':
    case 'ru':
      defaultLanguage = 'ru';
      break;
    default:
      defaultLanguage = 'en';
      break;
  }

  return defaultLanguage;
};

export const availableLanguages = [
  'en',
  'ru',
  // 'fr',
  // 'de',
  'es',
];
