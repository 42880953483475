/* global window */
// @flow
import React from 'react';

import Logos from '../../logos/logos';

import copy from '../../../config/copy';
import { localize } from '../../../util/helpers';

import styles from './menu.module.scss';

interface Props {
  visible: boolean,
  goToRoute: Function,
}

const Menu = ({ visible, goToRoute } : Props) => (
  <div
    className={`${styles.menuWrapper} grid-container${visible ? ` ${styles.visible}` : ''}`}
  >
    <div className={styles.flexWrapper}>
      <ul className={styles.menuList}>
        <li className={styles.menuItems}>
          <h1
            className="medium no-prevent"
            onClick={() => goToRoute(`/${window.locale}`)}
          >
            {localize(copy.essityxfotografiska.text3)}
          </h1>
        </li>
        <li className={styles.menuItems}>
          <h1
            className="medium no-prevent"
            onClick={() => goToRoute(`/${window.locale}/${localize(copy.essityxfotografiska.text7)}`)}
          >
            {localize(copy.essityxfotografiska.text5)}
          </h1>
        </li>
        <li className={styles.menuItems}>
          <h1
            className="medium no-prevent"
            onClick={() => goToRoute(`/${window.locale}/${localize(copy.essityxfotografiska.text8)}`)}
          >
            {localize(copy.essityxfotografiska.text4)}
          </h1>
        </li>
      </ul>
    </div>
    <Logos category="Menu Item" className={styles.logoContainer} isWhite />
  </div>
);

export default Menu;
