// @flow
import React, { useCallback, useState } from 'react';

import Logos from '../logos/logos';

import NavigationItem from './navigation-item/navigation-item';
import type { Data as NavigationItemData } from './navigation-item/navigation-item';

import styles from './navigation.module.scss';

interface Props {
  items: Array<NavigationItemData>,
}

const Navigation = ({ items } : Props) => {
  const [activeItem, setActiveItem] = useState(null);

  const onActiveChage = useCallback((id: number, isActive: boolean) => {
    if (isActive) {
      setActiveItem(id);
    } else {
      setActiveItem(null);
    }
  }, []);

  const itemsRender = items.map((item) => (
    <NavigationItem
      key={item.id}
      passive={activeItem !== null && activeItem !== item.id}
      active={activeItem !== null && activeItem === item.id}
      onActiveChange={onActiveChage}
      data={item}
    />
  ));

  return (
    <div className={styles.navigation}>
      <div className={styles.items}>
        {itemsRender}
      </div>
      <Logos category="Navigation" className={styles.logoContainer} />
    </div>
  );
};

export default Navigation;
