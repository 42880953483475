
import Story1Content from './stories/story1';
import Story2Content from './stories/story2';
import Story3Content from './stories/story3';
import Story4Content from './stories/story4';
import Story5Content from './stories/story5';

import images from './images';

import copy from './copy';

export const stories = [
  {
    id: 0,
    title: copy.story1.text1,
    url: copy.story1.text2,
    navImg: images.nav.story1,
    navMobile: images.navMobile.story1,
    content: Story1Content,
    explore: copy.essityxfotografiska.text1,
    metaDescr: copy.story1.text3,
    position: '1 / 5',
    orientation: 'landscape',
    contentPosition: 'right',
  },
  {
    id: 1,
    title: copy.story2.text1,
    url: copy.story2.text2,
    navImg: images.nav.story2,
    navMobile: images.navMobile.story2,
    content: Story2Content,
    explore: copy.essityxfotografiska.text1,
    metaDescr: copy.story2.text3,
    position: '2 / 5',
    orientation: 'portrait',
    contentPosition: 'right',
  },
  {
    id: 2,
    title: copy.story3.text1,
    url: copy.story3.text2,
    navImg: images.nav.story3,
    navMobile: images.navMobile.story3,
    content: Story3Content,
    explore: copy.essityxfotografiska.text1,
    metaDescr: copy.story3.text3,
    position: '3 / 5',
    orientation: 'landscape',
    contentPosition: 'left',
  },
  {
    id: 3,
    title: copy.story4.text1,
    url: copy.story4.text2,
    navImg: images.nav.story4,
    navMobile: images.navMobile.story4,
    content: Story4Content,
    explore: copy.essityxfotografiska.text1,
    metaDescr: copy.story4.text3,
    position: '4 / 5',
    orientation: 'landscape',
    contentPosition: 'right',
  },
  {
    id: 4,
    title: copy.story5.text1,
    url: copy.story5.text2,
    navImg: images.nav.story5,
    navMobile: images.navMobile.story5,
    content: Story5Content,
    explore: copy.essityxfotografiska.text1,
    metaDescr: copy.story5.text3,
    position: '5 / 5',
    orientation: 'landscape',
    contentPosition: 'left',
  },
];

export const about = {
  content: [
    {
      component: 'hero',
      data: {
        src: images.about.desktop,
        mobileSrc: images.about.mobile,
        title: copy.about.text1,
      },
    },
    {
      component: 'intro',
      data: {
        copy: [
          copy.about.text2,
          copy.about.text3,
        ],
        noAnimation: true,
      },
    },
    {
      component: 'bigImage',
      data: {
        src: images.about.img1,
        details: {
          copy: [copy.about.text4],
        },
        credit: copy.about.text22,
        noAnimation: true,
      },
    },
    {
      component: 'text',
      data: {
        copy: [copy.about.text5, copy.about.text6],
      },
    },
    {
      component: 'image',
      data: {
        type: 'portrait right',
        src: images.about.img2,
        details: {
          copy: [copy.about.text7],
        },
        credit: copy.about.text23,
      },
    },
    {
      component: 'text',
      data: {
        copy: [copy.about.text8, copy.about.text9, copy.about.text10],
      },
    },
    {
      component: 'image',
      data: {
        type: 'landscape left',
        src: images.about.img3,
        details: {
          copy: [copy.about.text11],
        },
        credit: copy.about.text24,
      },
    },
    {
      component: 'text',
      data: {
        title: copy.about.text12,
        copy: [copy.about.text13, copy.about.text14, copy.about.text15],
      },
    },
    {
      component: 'image',
      data: {
        type: 'landscape right',
        src: images.about.img4,
        details: {
          copy: [copy.about.text16],
        },
        credit: copy.about.text25,
      },
    },
    {
      component: 'text',
      data: {
        copy: [copy.about.text17, copy.about.text18],
      },
    },
    {
      component: 'text',
      data: {
        title: copy.about.text19,
        copy: [copy.about.text20, copy.about.text2],
      },
    },
  ],
  type: 'about',
  description: copy.essityxfotografiska.text10,
  title: copy.essityxfotografiska.text4,
};

export const report = {
  content: [
    {
      component: 'hero',
      data: {
        src: images.report.desktop,
        mobileSrc: images.report.mobile,
        title: copy.essityxfotografiska.text5,
      },
    },
    {
      component: 'bigImage',
      data: {
        src: images.report.desktop,
        noAnimation: true,
      },
    },
    {
      component: 'text',
      data: {
        noAnimation: true,
        title: copy.report.text1,
        copy: [copy.report.text2, copy.report.text3],
      },
    },
    {
      component: 'quote',
      data: {
        text: copy.report.text4,
        author: copy.report.text5,
      },
    },
    {
      component: 'image',
      data: {
        type: 'landscape left',
        src: images.content.story2.img3,
        credit: copy.report.text11,
      },
    },
    {
      component: 'text',
      data: {
        copy: [copy.report.text6],
      },
    },
    {
      component: 'text',
      data: {
        title: copy.report.text7,
        copy: [copy.report.text8, copy.report.text9, copy.report.text10],
      },
    },
  ],
  type: 'report',
  description: copy.essityxfotografiska.text10,
  title: copy.essityxfotografiska.text5,
};

export const privacy = {
  text: copy.essityxfotografiska.text9,
};
