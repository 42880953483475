/* global document */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './util/modernizr-custom';
import App from './app/app';

import * as serviceWorker from './serviceWorker';

import './styles/fonts.scss';
import './styles/theme.scss';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.register();
