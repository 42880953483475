// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './video.module.scss';

interface Props {
  data: {
    type?: string,
    title?: {[string]: string},
    videoId: {[string]: string},
    note?: {[string]: string},
  }
}

const Video = ({ data } : Props) => {
  const videoSrc = `https://www.youtube-nocookie.com/embed/${localize(data.videoId)}?modestbranding=1&rel=0`;

  let title;

  if (data.title) {
    title = (
      <h4
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: localize(data.title) }}
      />
    );
  }

  let note;

  if (data.note) {
    note = (
      <div
        className={styles.note}
      >
        <p
          className="tiny"
          dangerouslySetInnerHTML={{ __html: localize(data.note) }}
        />
      </div>
    );
  }

  let extraStyles = '';

  if (data.type) {
    const types = data.type.split(' ');

    for (let i = 0; i < types.length; i++) {
      const type = types[i];

      if (type && type.trim().length > 0) {
        extraStyles += `${styles[type]} `;
      }
    }
  }

  return (
    <div className={`${styles.video} ${extraStyles} scroll-animation-component`}>
      {title}
      <div className={styles.videoWrapper}>
        <iframe
          title="video-player"
          className={styles.ytPlayer}
          src={videoSrc}
          frameBorder="0"
          type="text/html"
        />
      </div>
      {note}
    </div>
  );
};

export default Video;
