// @flow
import React from 'react';

import { localize } from '../../../../util/helpers';

import styles from './text-with-headline.module.scss';

interface Props {
  data: {
    headline: {[string]: string},
    copy?: Array<{[string]: string}>,
    noAnimation?: boolean,
  },
}

const TextWithHeadline = ({ data } : Props) => {
  let pContainer;

  if (data.copy && data.copy.length > 0) {
    const paragraphs = data.copy.map((paragraph, index) => (
      <p
        className="tiny"
        key={index}
        dangerouslySetInnerHTML={{ __html: localize(paragraph) }}
      />
    ));

    pContainer = (
      <div className={styles.pContainer}>
        {paragraphs}
      </div>
    );
  }

  return (
    <div className={`${styles.textWithHeadline} ${data.noAnimation ? '' : 'scroll-animation-component'}`}>
      <h1>
        {localize(data.headline)}
      </h1>
      {pContainer}
    </div>
  );
};

export default TextWithHeadline;
